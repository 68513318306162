<template>
  <div class="add-report-container">
    <RButton
      :sm="size === 'sm'"
      :xs="size === 'xs'"
      :color="color"
      :dark="dark"
      @click="openModal"
    >
      <v-icon>mdi-plus</v-icon>
      יצירת דוח חדש
    </RButton>
    <RModal v-model="isOpen" title="יצירת דוח חדש">
      <form
        :class="{
          'is-loading': isLoading,
        }"
        @submit.prevent="createReport"
      >
        <RFieldText
          v-model="customerName"
          name="customerName"
          class="mb-3"
          label="שם/מספר הלקוח *"
          required
        />

        <RFieldText
          v-model="address"
          class="mb-3"
          name="address"
          label="כתובת *"
          required
        />

        <RSelectList
          v-if="reportTemplates && reportTemplates.length"
          v-model="template"
          class="mb-3"
          :items="reportTemplates"
          label="תבנית *"
          :nullable="false"
          placeholder="-"
        />

        <RSimpleCard v-else title="😬 אין לכם תבניות עדיין" class="mb-3">
          <div>
            מוסיפים תבנית מספריית התבניות שלנו, יוצרים חדשה או מעלים את הדוח
            שלכם.
          </div>

          <RButton
            v-if="$checkRole(['admin', 'owner'])"
            class="mt-3"
            sm
            color="grey"
            to="/report-templates/?new=1"
          >
            מעבר להוספת תבנית
          </RButton>
        </RSimpleCard>

        <template v-if="shouldSeeProjectSelection">
          <RFieldCheckbox
            v-model="attachToProject"
            label="שיוך דוח לפרויקט"
            class="mb-3"
          />

          <Transition name="slide">
            <RSelectList
              v-if="attachToProject"
              v-model="project"
              placeholder="-"
              :items="projects"
              label="פרויקט"
            />
          </Transition>
        </template>

        <RFieldCheckbox v-model="isDraft" label="הגדר דוח כטיוטה (ללא עלות)" />

        <RButton
          v-if="!isDraft"
          class="mt-4"
          color="primary"
          block
          lg
          :loading="isLoading"
          :disabled="!isFormValid"
          type="submit"
        >
          יצירת דוח
        </RButton>
        <RButton
          v-if="isDraft"
          class="mt-4"
          block
          lg
          color="grey"
          :loading="isLoading"
          :disabled="!isFormValid"
          type="submit"
        >
          יצירת דוח טיוטה
        </RButton>
      </form>
    </RModal>
  </div>
</template>

<script>
import ReportsApi from "@/api/ReportsApi";

export default {
  props: {
    size: {
      type: String,
      default: "md",
    },
    color: {
      type: String,
      default: "black",
    },
    dark: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "all", // all, template, project
    },
    loadTemplate: {
      type: Object,
      default: null,
    },
    loadProject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      customerName: null,
      address: null,
      isDraft: false,
      template: null,
      project: null,
      attachToProject: false,
    };
  },
  computed: {
    shouldSeeProjectSelection() {
      return this.mode === "all" && this.projects.length;
    },
    isFormValid() {
      if (this.attachToProject) {
        return (
          this.customerName && this.address && this.template && this.project
        );
      } else {
        return this.customerName && this.address && this.template;
      }
    },
    reportTemplates() {
      let reportTemplates = this.$store.state.reportTemplates.reportTemplates;
      if (this.project) {
        reportTemplates = reportTemplates.filter((template) =>
          this.project.reportTemplates.some(
            (projectTemplate) => projectTemplate.id === template.id
          )
        );
      }
      return reportTemplates;
    },
    projects() {
      return this.$store.state.projects.projects.filter(
        (project) => project.reportTemplates.length > 0
      );
    },
  },
  watch: {
    isOpen(newVal) {
      if (!newVal) {
        this.resetModal();
      }
    },
    attachToProject(newVal) {
      if (!newVal) {
        this.project = null;
      } else {
        this.template = null;
      }
    },
    project(newVal) {
      if (newVal) {
        this.address = this.project.address;
        this.customerName = this.project.customer_name;

        if (
          this.template &&
          !this.project.reportTemplates.some(
            (template) => template.id === this.template.id
          )
        ) {
          this.template = null;
        }
      }
    },
  },
  created() {
    if (this.mode === "all") {
      this.$store.dispatch("reportTemplates/fetchReportTemplates");
      this.$store.dispatch("projects/fetchProjects");
    }

    if (this.mode === "template") {
      this.template = this.loadTemplate;
    }

    if (this.mode === "project") {
      this.$store.dispatch("reportTemplates/fetchReportTemplates");
      this.project = this.loadProject;
      this.attachToProject = true;
    }
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    resetModal() {
      this.isLoading = false;
      this.customerName = null;
      this.address = null;
      this.isDraft = false;
      this.template = null;
      this.project = null;
    },
    async createReport() {
      this.isLoading = true;

      const data = await ReportsApi.createReport({
        reportTemplateId: this.template.id,
        address: this.address,
        customerName: this.customerName,
        isDraft: this.isDraft ? this.isDraft : false,
        projectId: this.project ? this.project.id : null,
      });

      this.isLoading = false;

      await this.$router.push({
        name: "reports.edit",
        params: { id: data.id },
      });
    },
  },
};
</script>

<style scoped>
.is-loading {
  pointer-events: none;
  opacity: 0.5;
}
</style>
