<template>
  <div class="max-w-[700px] mx-auto px-4 py-[52px]">
    <section class="rp-s-headings">
      <h3 class="rp-h3 rp-s-headings__title">עדכונים, חידושים ושיפורים</h3>
      <div class="rp-s-headings__text">
        אנחנו תמיד משפרים את המערכת עבור לקוחותינו :)
      </div>
    </section>
    <br />

    <div v-if="updates.length" class="update-card">
      <RSimpleCard
        v-for="(update, updateIndex) in updates"
        :key="updateIndex"
        :title="update.title"
        :subtitle="$Utils.presentHebrewDate(update.created_at)"
        class="mb-5"
      >
        <div
          v-if="update.type === 'improvement'"
          style="color: blue; font-weight: 600"
        >
          שיפור
        </div>
        <div v-if="update.type === 'new'" style="color: teal; font-weight: 600">
          חדש
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="update-card__text mt-2"
          style="color: #000"
          v-html="update.content"
        ></div>
        <!-- eslint-enable -->
      </RSimpleCard>
    </div>

    <RIconLoading v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("updates", ["updates"]),
  },
  created() {
    this.$store.dispatch("updates/fetchUpdates");
  },
};
</script>

<style lang="scss">
.update-card {
  img {
    max-width: 100%;
    height: auto;
  }
  .v-chip {
    font-weight: 600;
  }
  &__text {
    a {
      text-decoration: underline;
    }
    p:last-child {
      margin-bottom: 0;
    }
    p:not(:last-child) {
      margin-bottom: 8px;
    }
    ol,
    ul {
      li:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}
</style>
