class LocalService {
  constructor() {
    this.keys = Object.freeze({
      imageEditorSettings: "imageEditorSettings",
      report: "report",
    });

    this.getItem = (key) => {
      return checkKey(key) ? JSON.parse(localStorage.getItem(key)) : null;
    };

    this.setItem = (key, value) => {
      // console.log("key, value, checkKey(key)", key, value, checkKey(key));

      return checkKey(key)
        ? localStorage.setItem(key, JSON.stringify(value))
        : null;
    };

    const checkKey = (key) => {
      if (!(key in this.keys)) {
        console.error(`Invalid key: ${key}`);
      }
      return key in this.keys;
    };
  }
}

const localService = new LocalService();

export default localService;
