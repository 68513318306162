<template>
  <div class="d-flex fieldPadding u-gap-2">
    <RFieldText
      label="עליון"
      :model-value="modelValue.top"
      type="number"
      min="0"
      max="100"
      step="1"
      style="flex: 1"
      @update:model-value="(value) => changeValueHandler(value, 'top')"
    />

    <RFieldText
      :model-value="modelValue.right"
      type="number"
      outlined
      min="0"
      max="100"
      step="1"
      label="ימין"
      hide-details
      dense
      style="flex: 1"
      @update:model-value="(value) => changeValueHandler(value, 'right')"
    />

    <RFieldText
      :model-value="modelValue.bottom"
      type="number"
      outlined
      min="0"
      max="100"
      step="1"
      label="תחתון"
      hide-details
      dense
      style="flex: 1"
      @update:model-value="(value) => changeValueHandler(value, 'bottom')"
    />

    <RFieldText
      :model-value="modelValue.left"
      type="number"
      outlined
      min="0"
      max="100"
      step="1"
      label="שמאל"
      hide-details
      dense
      style="flex: 1"
      @update:model-value="(value) => changeValueHandler(value, 'left')"
    />

    <RButtonIcon
      class="mt-7"
      :color="modelValue.isLocked ? 'primary' : 'secondary'"
      :icon="modelValue.isLocked ? 'mdi-link' : 'mdi-link-off'"
      @click="lockValueHandler"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    lockValue: true,
  }),
  methods: {
    changeValueHandler(val, field) {
      console.log('changeValueHandler', val, field);
      const newVal = parseInt(val);
      const res = { ...this.modelValue };
      if (this.modelValue.isLocked) {
        res["top"] = newVal;
        res["bottom"] = newVal;
        res["left"] = newVal;
        res["right"] = newVal;
      } else {
        res[field] = newVal;
      }
      this.$emit("update:modelValue", res);
    },
    lockValueHandler() {
      const res = { ...this.modelValue };
      res["isLocked"] = !this.modelValue.isLocked;
      this.$emit("update:modelValue", res);

      setTimeout(() => {
        if (this.modelValue.isLocked) {
          const maxValue = Math.max(...Object.values(this.modelValue));
          this.changeValueHandler(maxValue);
        }
      }, 10);
    },
  },
};
</script>

<style scoped lang="scss">
.fieldPadding {
  > .v-input {
    margin-left: 5px;
  }
}
</style>
