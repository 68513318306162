import {appVersion} from "@/common/appVersion";
import posthog from "posthog-js";
import {Utils} from "@/common/Utils";
// import { retryWithBackoff } from "../common/Utils";

const sendLog = async (message, payload = null) => {
    // if (payload) payload = _createPayload(payload);

    const body = {
        // user_agent: navigator.userAgent,
        // platform: navigator.platform,
        app_version: appVersion,
        // message,
    };
    if (payload) {
        body.payload = payload;
    }
    try {
        console.log("sendLog: Logging event: " + message, body);
        if (Utils.getEnv() !== "local") {
            posthog.capture(message, {
                distinct_id: posthog.get_distinct_id(), // Ensure events are tied to a unique user
                ...body,
            });
        }
    } catch (err) {
        console.error("sendLog: Error sending log", payload, err);
    }
};

function _createPayload({err, data}) {
    if (err) {
        err = {
            message: err.message || "No error message available",
            code: err.code || "No code",
            method: err.config?.method || "Unknown method", // HTTP method (GET, POST, etc.)
            url: err.config?.url || "No URL specified",
            responseStatus: err.response?.status || "No response status",
            responseData: err.response?.data || "No response data",
            requestStatus: err.request
                ? "Request made but no response received"
                : "No request sent",
            headers: err.config?.headers || "No headers",
        };
    }
    let result = {};
    if (err) {
        result.error = err;
    }
    if (data) {
        result.additionalData = data;
    }
    if (Object.keys(result).length === 0) {
        return null;
    }
    return result;
}

export {sendLog};
