<template>
  <div class="pl-2 mb-2">
    <label class="mb-2 d-block subtitle-2">{{ label }}</label>

    <div class="input-field-signature">
      <div v-if="inputText" class="input-field-signature-preview">
        <img :src="previewImageUrl" alt="signature preview" />
      </div>

      <RButton
        outlined
        color="primary"
        icon-before="mdi-lead-pencil"
        @click="openModal"
      >
        {{ buttonText }}
      </RButton>

      <RModal v-if="isModalOpen" v-model="isModalOpen" :title="modalTitle">
        <form @submit.prevent="saveSignature">
          <div
            style="width: 220px; height: 110px; margin: 0 auto; max-width: 100%"
          >
            <VueSignaturePad
              ref="signaturePad"
              :custom-style="{
                border: '1px solid #DADADA',
                'border-radius': '4px',
                'box-shadow': '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
              }"
            />
          </div>

          <RButton
            class="mt-3 mb-3"
            color="red"
            outlined
            sm
            icon-before="mdi-delete"
            @click="clearSignature"
          >
            ניקוי חתימה
          </RButton>

          <RButton block lg color="primary" type="submit">
            שמירת חתימה
          </RButton>
        </form>
      </RModal>
    </div>
  </div>
</template>

<script>
import { Utils } from "@/common/Utils";
import { VueSignaturePad } from "vue-signature-pad";

export default {
  components: {
    VueSignaturePad,
  },
  props: {
    modalTitle: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalOpen: false,
      inputText: "",
      canChange: false,
    };
  },
  computed: {
    buttonText() {
      if (!this.inputText || this.inputText.length < 2) {
        return "הוספת חתימה";
      }
      return "שינוי חתימה";
    },
    previewImageUrl() {
      if (Utils.isBase64Image(this.inputText)) {
        return this.inputText;
      } else {
        return this.$Reporto.globals.MEDIA_URL + this.inputText;
      }
    },
  },
  watch: {
    inputText(val) {
      if (this.canChange) {
        if (val === undefined) {
          this.$emit("update:modelValue", "");
        } else {
        this.$emit("update:modelValue", val);
        }
      }
    },
  },
  mounted() {
    this.inputText = this.modelValue;
    setTimeout(() => {
      this.canChange = true;
    }, 10);
  },
  methods: {
    closeModal() {
      this.isModalOpen = false;
    },
    openModal() {
      this.isModalOpen = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.signaturePad.resizeCanvas();
        }, 1);
      });
    },
    saveSignature() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.inputText = data;
      this.closeModal();
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field-signature {
  &-preview {
    img {
      max-width: 100%;
      max-height: 82px;
      height: auto;
      width: auto;
      border: 1px solid #dadada;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
      margin-bottom: 10px;
      padding: 5px;
      background: #fff;
    }
  }
}
</style>
