<template>
  <div class="r-page-site-page-details">
    <div
      style="
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        max-width: 1200px;
        margin-inline: auto;
      "
    >
      <RSimpleCard :disabled="isLoading" class="mb-6" title="פרטים כלליים">
        <RFields>
          <RFieldText v-model="site.number" label="מספר פרויקט" />
          <RFieldText v-model="site.address" label="כתובת" />
          <RFieldImage v-model="site.image" label="תמונת הפרויקט" />
          <RFieldWysiwyg v-model="site.top_text" label="טקסט עליון" />
          <RFieldWysiwyg v-model="site.bottom_text" label="טקסט תחתון" />

          <RButton block lg :loading="isLoading" @click="updateSiteDetails">
            שמירה
          </RButton>
        </RFields>
      </RSimpleCard>
      <RSimpleCard :disabled="isLoading" class="mb-6" title="מפלסים">
        <div class="levels-list">
          <div
            v-for="(level, levelIndex) in levels"
            :key="levelIndex"
            class="levels-list-item"
          >
            <div class="levels-list-item-header">
              <div class="levels-list-item-title">
                {{ level.text }}
              </div>
              <RButton xs color="red" outlined @click="removeLevel(level.id)"
                >מחיקת מפלס</RButton
              >
            </div>
            <div class="levels-list-item-sublist">
              <div
                v-for="(area, areaIndex) in getLevelAreas(level.id)"
                :key="areaIndex"
                class="levels-list-item-sublist-item"
              >
                <div v-if="editAreaId === area.id" class="d-flex u-gap-2">
                  <input v-model="editAreaText" type="text" />
                  <RButton xs color="green" @click="saveArea">שמירה</RButton>
                </div>
                <div v-else class="levels-list-item-sublist-item-title">
                  {{ area.text }}
                </div>
                <div class="levels-list-item-sublist-item-actions">
                  <RActions>
                    <RAction
                      icon="mdi-pencil"
                      text="עריכה"
                      @click="editArea(area)"
                    />
                    <RAction
                      icon="mdi-delete"
                      text="מחיקה"
                      @click="removeArea(area.id)"
                    />
                  </RActions>
                </div>
              </div>
            </div>

            <div
              class="levels-list-item-add"
              @click="openAddAreaModal(level.id)"
            >
              + אזור חדש
            </div>
          </div>
        </div>
      </RSimpleCard>
      <RModal v-model="addAreaModal.isOpen" title="הוספת אזור">
        <RFields>
          <RFieldText v-model="addAreaModal.areaText" label="שם האזור" />
          <RButton :disabled="!addAreaModal.areaText" lg block @click="addArea">
            הוספת אזור
          </RButton>
        </RFields>
      </RModal>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addAreaModal: {
        isOpen: false,
        areaText: null,
      },
      editAreaId: null,
      editAreaText: null,
      isLoading: false,
      siteDetails: {
        number: null,
        address: null,
        image: null,
        top_text: null,
        bottom_text: null,
      },
    };
  },
  computed: {
    site() {
      return this.$store.state.site.site;
    },
    levels() {
      return this.$store.state.site.site.levels;
    },
  },
  created() {
    this.siteDetails = {
      number: this.site.number,
      address: this.site.address,
      image: this.site.image,
      top_text: this.site.top_text,
      bottom_text: this.site.bottom_text,
    };
  },
  methods: {
    removeLevel(levelId) {
      if (!confirm("האם אתה בטוח שברצונך למחוק את המפלס?")) {
        return;
      }
      this.$store.dispatch("site/removeLevel", levelId);
    },
    openAddAreaModal(levelId) {
      this.addAreaModal.isOpen = true;
      this.addAreaModal.levelId = levelId;
      this.addAreaModal.areaText = null;
    },
    addArea() {
      this.$store.dispatch("site/addArea", {
        text: this.addAreaModal.areaText,
        levelId: this.addAreaModal.levelId,
      });
      this.addAreaModal.isOpen = false;
      this.addAreaModal.areaText = null;
      this.$toast.success("האזור נוסף בהצלחה");
    },
    async removeArea(areaId) {
      if (!confirm("האם אתה בטוח שברצונך למחוק את האזור?")) {
        return;
      }
      await this.$store.dispatch("site/removeArea", areaId);
      this.editAreaId = null;
      this.editAreaText = null;
    },
    saveArea() {
      this.$store.dispatch("site/updateAreaText", {
        id: this.editAreaId,
        text: this.editAreaText,
      });
      this.editAreaId = null;
      this.editAreaText = null;
    },
    editArea(area) {
      this.editAreaId = area.id;
      this.editAreaText = area.text;
    },
    getLevelAreas(levelId) {
      let areas = [];

      this.site.levels_areas.forEach((level_area) => {
        if (level_area.level_id === levelId) {
          const area = this.site.areas.find((a) => a.id === level_area.area_id);
          areas.push(area);
        }
      });

      return areas;
    },
    updateSiteDetails() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.levels-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  &-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: 0.2s;
    overflow: hidden;

    &-add {
      padding: 8px 10px;
      border-top: 1px solid #ccc;
      cursor: pointer;
      transition: 0.2s;
      color: #777;
      font-weight: 500;
      &:hover {
        background: #fafafa;
      }
    }

    &-header {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background: #eee;
      border-bottom: 1px solid #ccc;
    }
    &-title {
      font-weight: 600;
    }
    &-sublist {
      &-item {
        padding: 8px 10px;
        transition: 0.2s;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          background: #eee;
          border: 1px solid #ccc;
          font-size: 14px;
          flex: 1;
          max-width: 300px;
          border-radius: 2px;
          padding: 0 4px;
          &:focus {
            outline: 0;
            border-color: #888;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }

        &-actions {
          display: flex;
          gap: 8px;
        }

        &-title {
          flex: 1;
        }
      }
    }
  }
}
</style>
