const DB_NAME = "reportoDB";
const STORE_NAME = "keyValueStore";
const DB_VERSION = 1;

class IndexedDBService {
  #objectStoreActionResult(func) {
    return new Promise((resolve, reject) => {
      this.#createStoreIfNeeded()
        .then(() => this.#openDB())
        .then((db) => {
          const transaction = db.transaction(STORE_NAME, "readwrite");
          const store = transaction.objectStore(STORE_NAME);
          const request = func(store);

          request.onsuccess = (event) => resolve(event.target.result?.value || event.target.result);
          request.onerror = () => reject(request.error);
        })
        .catch(reject);
    });
  }

  #openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);
      request.onerror = () => reject(request.error);
      request.onsuccess = (event) => resolve(event.target.result);
    });
  }

  #createStoreIfNeeded() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);
      
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: "key" });
        }
      };

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve();
    });
  }

  async setItem(key, value) {
    return this.#objectStoreActionResult((store) => 
      store.put({ key: String(key), value })
    );
  }

  async getItem(key) {
    return this.#objectStoreActionResult((store) => 
      store.get(String(key))
    );
  }

  async removeItem(key) {
    return this.#objectStoreActionResult((store) => 
      store.delete(String(key))
    );
  }

  async getAllKeys() {
    return this.#objectStoreActionResult((store) => 
      store.getAllKeys()
    );
  }
}

const indexedDBService = new IndexedDBService();
export default indexedDBService;
