<template>
  <div class="app-field">
    <RFieldText
      :model-value="modelValue"
      label="רוחב"
      type="number"
      min="0"
      step="1"
      max="100"
      @update:model-value="changeValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changeValue(val) {
      this.$emit("update:modelValue", parseFloat(val));
    },
  },
};
</script>
