<template>
  <div class="r-field r-field--text">
    <label v-if="label" class="r-field-label"> {{ label }} </label>
    <div class="r-field-input">
      <v-textarea
        v-model="inputText"
        outlined
        hide-details
        rows="1"
        dense
        auto-grow
        v-bind="$attrs"
        color="primary"
        variant="outlined"
        density="compact"
        @keyup.native="inputText = $event.target.value"
      />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  data() {
    return {
      inputText: this.modelValue,
    };
  },
  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },
  created() {
    // this.inputText = this.value ? this.value : "";
  },
  methods: {
    updateParentValue(val) {
      this.inputText = val;
    },
    emitChange(event) {
      this.$emit("change", event.target.value);
    },
  },
};
</script>
