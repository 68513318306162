<template>
  <div class="min-h-screen bg-gray-50 p-8" dir="rtl">
    <div class="max-w-7xl mx-auto">
      <!-- Header Section -->
      <div class="bg-white rounded-lg shadow-sm p-6 mb-6">
        <h2 class="text-2xl font-semibold text-gray-800 mb-4">בדיקת אחסון</h2>
        <div v-if="dbSize" class="flex items-center space-x-2 text-gray-600">
          <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                  d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4"/>
          </svg>
          <span>נפח אחסון: {{ formatSize(dbSize) }}</span>
        </div>
      </div>

      <!-- Content Section -->
      <div class="bg-white rounded-lg shadow-sm p-6">
        <div v-if="error" class="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
          {{ error }}
        </div>
        
        <div v-else-if="loading" class="flex justify-center items-center py-12">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
        
        <div v-else>
          <div class="flex items-center justify-between mb-4">
            <h3 class="text-lg font-medium text-gray-700">תור סנכרון הודעות</h3>
            <span class="bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full">
              {{ messages.length }} פריטים
            </span>
          </div>

          <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    מספר
                  </th>
                  <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    מידע
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-if="messages.length === 0">
                  <td colspan="2" class="px-6 py-4 text-center text-gray-500">
                    לא נמצאו הודעות בתור הסנכרון
                  </td>
                </tr>
                <tr v-for="(message, index) in messages" :key="index" class="hover:bg-gray-50">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ index + 1 }}
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-500">
                    <pre style="text-align:right;direction:rtl;" v-if="message?.data" class="whitespace-pre-wrap">{{ formatData(message.data) }}</pre>
                    <span v-else class="text-gray-400">אין מידע</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: null,
      loading: true,
      error: null,
      dbSize: 0
    }
  },
  methods: {
    formatSize(bytes) {
      if (bytes === 0) return '0 בייט'
      const k = 1024
      const sizes = ['בייט', 'קילובייט', 'מגהבייט', 'גיגהבייט']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    },
    formatData(data) {
      const isBase64 = (str) => {
        if (typeof str !== 'string') return false;
        // Check for data:image pattern
        if (str.match(/^data:image\/[a-z]+;base64,/)) return true;
        // Check for long base64-like strings
        if (str.length > 100 && str.match(/^[A-Za-z0-9+/=]+$/)) return true;
        return false;
      };

      const processValue = (value) => {
        if (typeof value === 'object' && value !== null) {
          // Handle arrays and objects recursively
          return Array.isArray(value) 
            ? value.map(v => processValue(v))
            : Object.fromEntries(
                Object.entries(value).map(([k, v]) => [k, processValue(v)])
              );
        }
        
        return isBase64(value) ? '[Base64 Content]' : value;
      };

      return JSON.stringify(processValue(data), null, 2);
    },
    async calculateStorageSize() {
      try {
        const estimate = await navigator.storage.estimate()
        this.dbSize = estimate.usage || 0
      } catch (e) {
        console.error('Error calculating storage size:', e)
      }
    },
    async getQueueData(store) {
      return new Promise((resolve, reject) => {
        const getRequest = store.get('vuex.reportQue.messagesSyncQueue');
        
        getRequest.onerror = (event) => {
          reject(event.target.error);
        };
        
        getRequest.onsuccess = (event) => {
          const data = event.target.result;
          if (data) {
            try {
              // Log the data to see what we're getting
              console.log('Raw data from IndexedDB:', data);
              // Ensure we're properly parsing the data
              const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
              resolve(Array.isArray(parsedData) ? parsedData : [parsedData]);
            } catch (e) {
              reject(new Error('Error parsing data: ' + e.message));
            }
          } else {
            resolve([]);
          }
        };
      });
    },
  },
  async mounted() {
    await this.calculateStorageSize();
    
    try {
      const request = indexedDB.open('localforage');
      
      request.onerror = (event) => {
        this.error = 'Error opening IndexedDB: ' + event.target.error;
        this.loading = false;
      };

      request.onsuccess = async (event) => {
        const db = event.target.result;
        const version = db.version;
        db.close();

        const newRequest = indexedDB.open('localforage', version);
        
        newRequest.onerror = (event) => {
          this.error = 'Error opening IndexedDB: ' + event.target.error;
          this.loading = false;
        };

        newRequest.onsuccess = async (event) => {
          try {
            const db = event.target.result;
            const transaction = db.transaction(['keyvaluepairs'], 'readonly');
            const store = transaction.objectStore('keyvaluepairs');
            
            // Use the new method to get queue data
            this.messages = await this.getQueueData(store);
            console.log('Processed messages:', this.messages);
          } catch (e) {
            this.error = e.message;
          } finally {
            this.loading = false;
          }
        };
      };
    } catch (e) {
      this.error = 'Unexpected error: ' + e.message;
      this.loading = false;
    }
  }
}
</script>
