<template>
  <div>
    <AppFieldSlider v-model="localItem.settings.size" label="גודל" />
  </div>
</template>

<script>
import AppFieldSlider from "@/components/ReportTemplate/components/AppFieldSlider.vue";

export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
  components: {
    AppFieldSlider,
  },
};
</script>
