<template>
  <div>
    <RFieldSelect
      v-model="localItem.settings.style"
      label="עיצוב"
      class="mb-4"
      :items="[{ text: 'באותה שורה', value: 'inline' }]"
    />

    <RFieldAlign
      v-model="localItem.settings.align"
      label="יישור"
      :items="['right', 'left']"
    />

    <RFieldCheckbox
      v-model="localItem.settings.multiple"
      class="mb-2"
      label="אפשר בחירה מרובה"
    />

    <div class="app-field">
      <label class="app-field__label">אפשרויות</label>
      <v-list class="pa-0 transparent" style="background: none">
        <draggable
          :list="localItem.settings.options"
          handle=".v-avatar"
          v-bind="dragOptions"
          itemKey="id"
        >
          <template #item="{ element: option }">
            <v-list-item
              :key="option.id"
              style="border: thin solid rgba(0, 0, 0, 0.12)"
              class="mb-2 rounded bg-white"
            >
              <template v-slot:prepend>
                <v-icon class="v-avatar" size="25"> mdi-drag</v-icon>
              </template>

              <v-layout>
                <input
                  v-model="option.label"
                  placeholder="הכניסו טקסט לבחירה"
                  type="text"
                  class="tableColumnOptions-input ml-2"
                  style="flex: 1"
                />
                <AppFieldColorPicker v-model="option.color" />
              </v-layout>

              <template v-slot:append>
                <RButtonIcon
                  @click="removeOption(option.id)"
                  icon="mdi-delete"
                  color="red"
                />
              </template>
            </v-list-item>
          </template>
        </draggable>
      </v-list>
    </div>

    <RButton
      icon-before="mdi-plus"
      color="primary"
      depressed
      block
      lg
      @click="addOption"
    >
      הוספת אפשרות
    </RButton>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { dragOptions } from "@/common/settings";
import { Utils } from "@/common/Utils";
import AppFieldColorPicker from "@/components/ReportTemplate/components/AppFieldColorPicker.vue";

export default {
  components: {
    draggable,
    AppFieldColorPicker,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
      dragOptions,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
    addOption() {
      this.localItem.settings.options.push({
        id: Utils.guid(),
        label: "",
        color: "#ffffff",
      });
    },
    removeOption(optionId) {
      this.localItem.settings.options = this.localItem.settings.options.filter(
        (option) => {
          return option.id !== optionId;
        }
      );
    },
  },
};
</script>
