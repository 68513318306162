<template>
  <div>
    <RFieldAlign
      v-model="localItem.settings.signatureAlign"
      label="ישור חתימה"
      :items="['right', 'left']"
    />

    <RAlert type="info"> את חתימת המשתמש עורכים דרך עמוד ״הפרופיל שלי״ </RAlert>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
