<template>
  <div>
    <RFieldText
      v-if="editContent"
      v-model="localItem.settings.headline"
      label="כותרת"
      class="mb-3"
    />

    <RFieldAlign
      v-if="editContent"
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <report-files
      label="קבצים"
      :load-list="localItem.settings.files"
      update-key="files"
      @do-update="doUpdateFiles"
      @do-update-many="doUpdateManyFiles"
    />
  </div>
</template>

<script>
import ReportFiles from "@/views/reports/components/ReportFiles.vue";
import { Utils } from "@/common/Utils";

export default {
  components: { ReportFiles },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: {
        settings: {
          files: {},
        },
      },
    };
  },
  watch: {
    localItem: {
      handler(val) {
        this.$emit("update:modelValue", val);
      },
      deep: true,
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
    async doUpdateFiles({ key, value }) {
      let cleanData = JSON.parse(
        JSON.stringify({
          [key]: value,
        })
      );

      let dataUploaded = await this.uploadObjectFiles(cleanData);

      this.localItem.settings = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );
    },
    async doUpdateManyFiles({ data }) {
      let cleanData = JSON.parse(JSON.stringify(data));

      let dataUploaded = await this.uploadObjectFiles(cleanData);

      this.localItem.settings = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );
    },
    async uploadObjectFiles(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (value && Utils.isBase64Image(value)) {
          let loader = this.$loading.show();
          let { data } = await Utils.uploadImageBase64(value);
          loader.hide();
          obj[key] = data.path;
        }
      }

      return obj;
    },
  },
};
</script>
