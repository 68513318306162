<template>
  <div>
    <report-label :label="item.settings.label" />
    <div class="rp-repeater with-floatingActions">
      <draggable
        :list="rows"
        handle=".rp-repeater__item__avatar"
        v-bind="dragOptions"
        item-key="id"
        @change="onDragChange"
      >
        <template #item="{ element: row }">
          <div
            class="rp-repeater__item"
            :class="{
              deleted: row.deletedAt,
              disabled: !row.isActive,
              'is-open': isOpen[row.id],
            }"
          >
            <div class="rp-repeater__item__floatingActions">
              <RIcon
                v-if="row.isActive && !row.deletedAt"
                size="20"
                class="mr-2"
                color="green"
                @click="setRowActive(row, false)"
                >mdi-eye</RIcon
              >

              <RIcon
                v-if="!row.isActive && !row.deletedAt"
                size="20"
                class="mr-2"
                color="grey"
                @click="setRowActive(row, true)"
              >
                mdi-eye-off</RIcon
              >
            </div>
            <div class="rp-repeater__item__header" @click="toggleOpen(row.id)">
              <div class="rp-repeater__item__avatar">
                <RIcon small class="mr-1 ml-2">mdi-drag</RIcon>
                <span>
                  {{ rows.indexOf(row) + 1 }}
                </span>
              </div>

              <div class="rp-repeater__item__title">
                {{ getRowPreviewText(row) }}
              </div>

              <div class="rp-repeater__item__arrow">
                <RIcon small>mdi-chevron-down</RIcon>
              </div>
            </div>
            <transition name="slide">
              <div
                v-if="isOpen[row.id]"
                class="rp-repeater__item__body"
              >
                <div
                  v-for="column in item.settings.columns"
                  :key="column.id"
                  style="padding: 15px"
                >
                  <template v-if="column.type === 'text'">
                    <RFieldText
                      v-model="row.columns[column.id]"
                      :label="column.settings.label"
                      @input="
                        localDoUpdate({
                          key: `${updateKey}.settings.rows.${row.id}.columns.${column.id}`,
                          value: $event.target?.value ?? $event,
                        })
                      "
                    />
                  </template>

                  <template v-if="column.type === 'signature'">
                    <RFieldSignature
                      :model-value="row.columns[column.id]"
                      :label="column.settings.label"
                      :modal-title="column.settings.label"
                      @update:model-value="
                        localDoUpdate({
                          key: `${updateKey}.settings.rows.${row.id}.columns.${column.id}`,
                          value: $event
                        })
                      "
                    />
                  </template>
                </div>

                <div style="padding: 15px">
                  <RButton
                    v-if="row.isActive"
                    color="grey"
                    outlined
                    icon-before="mdi-pause"
                    @click="setRowActive(row, false)"
                  >
                    כיבוי חתימה
                  </RButton>

                  <RButton
                    v-if="!row.isActive"
                    color="green"
                    outlined
                    icon-before="mdi-toggle-switch"
                    @click="setRowActive(row, true)"
                  >
                    הפעלת חתימה
                  </RButton>

                  <br />

                  <RButton
                    class="mt-3"
                    color="red"
                    outlined
                    icon-before="mdi-delete"
                    @click="removeRow(row)"
                  >
                    מחיקת חתימה
                  </RButton>
                </div>
              </div>
            </transition>
          </div>
        </template>
      </draggable>
    </div>

    <RButton
      outlined
      color="primary"
      class="mt-3"
      icon-before="mdi-plus"
      @click="addRow()"
    >
      <span>הוספת חתימה</span>
    </RButton>
  </div>
</template>

<script>
import { dragOptions } from "@/common/settings";
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import ReportLabel from "@/views/reports/components/ReportLabel";
import { Utils } from "@/common/Utils";

export default {
  components: {
    draggable,
    ReportLabel,
  },
  props: {
    loadItem: {
      type: Object,
      default: () => ({}),
    },
    updateKey: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      item: this.loadItem,
      rows: [],
      isOpen: {},
      dragOptions,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    hasCustomDoUpdate() {
      return this.$attrs && this.$attrs['onDoUpdate'];
    },
    hasCustomDoUpdateMany() {
      return this.$attrs && this.$attrs['onDoUpdateMany'];
    },
  },
  created() {
    this.initRows(this.item.settings.rows);
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),

    initRows(rows) {
      if (!rows) {
        this.rows = [];
        return;
      }

      // Convert to array and create a new reference
      this.rows = Object.values(rows);

      // Find max order
      let maxOrder = Math.max(
        ...this.rows
          .map((o) => o.order)
          .filter(el => el != null)
      );

      if (maxOrder === -Infinity) {
        maxOrder = 0;
      }
      maxOrder++;

      // Update rows with missing order and columns
      this.rows = this.rows.map(row => {
        const updatedRow = { ...row };
        if (!updatedRow.order) {
          updatedRow.order = maxOrder++;
        }
        if (!updatedRow.columns) {
          updatedRow.columns = {};
        }
        return updatedRow;
      });

      // Sort the array
      this.rows.sort((a, b) => a.order - b.order);
    },

    toggleOpen(id) {
      this.isOpen[id] = !this.isOpen[id];
    },

    getRowPreviewText(row) {
      let previewText = '';
      let previewTextForSignature = '';

      const columns = Object.values(this.item.settings.columns);

      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];

        if (column.type === "text") {
          if (row.columns[column.id]) {
            previewText += Utils.stripHtmlTags(row.columns[column.id]);
          }

          if (row.columns[column.id] && i !== columns.length - 1) {
            previewText += ", ";
          }
        }

        if (column.type === "signature") {
          previewTextForSignature = row.columns[column.id] ? "חתום" : "לא חתום";
        }
      }

      return previewTextForSignature + " - " + previewText;
    },

    async onDragChange() {
      // Update rows order reactively
      this.rows = this.rows.map((item, index) => ({
        ...item,
        order: index + 1
      }));

      const data = {};
      for (const row of this.rows) {
        data[`${this.updateKey}.settings.rows.${row.id}.order`] = row.order;
      }

      await this.localDoUpdateMany({ data });
    },

    async setRowActive(row, isActive) {
      row.isActive = isActive;

      await this.localDoUpdate({
        key: `${this.updateKey}.settings.rows.${row.id}.isActive`,
        value: row.isActive,
      });

      this.$forceUpdate();
    },

    removeRow(row) {
      if (confirm("בטוח למחוק את החתימה?")) {
        row.deletedAt = this.$Utils.currentTimestamp();

        this.localDoUpdate({
          key: `${this.updateKey}.settings.rows.${row.id}.deletedAt`,
          value: row.deletedAt,
        });

        this.$forceUpdate();
      }
    },

    async addRow() {
      let maxOrder = Utils.findMaxOrderInObject(this.rows);
      let newRow = {
        id: Utils.guid(),
        columns: {},
        isActive: true,
        order: maxOrder + 1,
      };

      // update server
      let data = {};
      for (const [key, value] of Object.entries(newRow)) {
        data[`${this.updateKey}.settings.rows.${newRow.id}.${key}`] = value;
      }
      await this.localDoUpdateMany({ data });

      // Update locally
      this.rows.push(JSON.parse(JSON.stringify(newRow)));

      this.toggleOpen(newRow.id);
    },

    async localDoUpdate({ key, value }) {
      if (this.hasCustomDoUpdate) {
        this.$emit('doUpdate', {
          key: key,
          value: value,
        });
      } else {
        await this.doUpdate({
          key: key,
          value: value,
        });
      }
    },

    async localDoUpdateMany({ data }) {
      if (this.hasCustomDoUpdateMany) {
        this.$emit('doUpdateMany', { data });
      } else {
        await this.doUpdateMany({ data });
      }
    },
  },
};
</script>
