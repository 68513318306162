import moment from "moment";
import { appDateFormat } from "@/common/settings";
import { reportTemplateItemTypes } from "@/common/settings";
import { env } from "@/services/EnvService";
import { setCookie, getCookie } from "@/utils/cookies";

export const Utils = {
  toastMessageFromResponse(e, defaultMessage = "שגיאה") {
    let toastMessage = "";

    if (e.response && e.response.data && e.response.data.errors) {
      const errorMessages = [];

      const errors = e.response.data.errors;
      for (const key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          const errorValue = errors[key];
          errorMessages.push(`${errorValue}`);
        }
      }

      if (errorMessages.length > 0) {
        toastMessage += errorMessages.join("\n");
      }
    }

    if (
      e.response &&
      e.response.data &&
      e.response.data.success === false &&
      e.response.data.message
    ) {
      if (!toastMessage) {
        toastMessage = e.response.data.message;
      }
    }

    if (!toastMessage) {
      toastMessage = defaultMessage;
    }

    return toastMessage;
  },
  findMaxOrderInObject(obj) {
    let arr = Object.values(obj);
    if (!arr.length) {
      return 0;
    }

    let maxOrder = Math.max(
      ...arr
        .map((o) => o.order)
        .filter(function (el) {
          return el != null;
        })
    );
    if (maxOrder === -Infinity) {
      maxOrder = 0;
    }
    return maxOrder;
  },
  convertObjectToArrayWithOrder(obj) {
    if (obj === undefined || Utils.isObjectEmpty(obj)) {
      return [];
    }

    let arr = Object.values(obj);

    // 0) find max 'order'
    let maxOrder = Math.max(
      ...arr
        .map((o) => o.order)
        .filter(function (el) {
          return el != null;
        })
    );
    if (maxOrder === -Infinity) {
      maxOrder = 0;
    }
    maxOrder++;

    // 1) add 'order' to missing rows.
    arr.forEach((row) => {
      if (!row.order) {
        row.order = maxOrder;
        maxOrder++;
      }
    });

    // 2) sort the array
    arr = arr.sort((a, b) => a.order - b.order);

    // 3) make sure 'order' are without spacing like 1,2,4 ...
    let order = 1;
    arr.forEach((row) => {
      row.order = order;
      order++;
    });

    return arr;
  },
  convertArrayToObject(arr) {
    let obj = {};
    if (!arr || !arr.length) {
      return obj;
    }

    arr.forEach((row) => {
      obj[row.id] = row;
    });
    return obj;
  },
  debounce(key, fn, delay = 500) {
    if (!window.debounceByKey) {
      window.debounceByKey = new Map();
    }
    if (window.debounceByKey.has(key)) {
      clearTimeout(window.debounceByKey.get(key));
    }
    window.debounceByKey.set(
      key,
      setTimeout(() => {
        window.debounceByKey.delete(key);
        fn();
      }, delay)
    );
  },
  debounceV2(fn, delay = 500) {
    let timerId = {};

    return function (...args) {
      const key = args[1]?.key || Date.now() + Math.random();

      if (timerId[key]) {
        clearTimeout(timerId[key]);
        timerId[key] = null;
      }

      timerId[key] = setTimeout(() => {
        fn.apply(this, args);
        timerId[key] = null;
      }, delay);
    };
  },
  delay: (function () {
    var timer = 0;
    return function (callback, ms = 500) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })(),
  isValidEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  isValidPhone(value) {
    if (!value) {
      return false;
    }

    if (value.length < 9) {
      return false;
    }

    if (value.includes("+")) {
      return false;
    }

    if (!value.startsWith(0) && !value.startsWith("972")) {
      return false;
    }

    value = value.replace(/\D/g, "");

    // if is mobile phone -- 0501111111 > +972501111111
    if (value.startsWith("05") && value.length === 10) {
      return true;
    }

    // if is regular phone -- 089388888 > +97289388888
    else if (value.startsWith("0") && value.length === 9) {
      return true;
    }
  },
  isIOSDevice() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },
  guid() {
    function e() {
      return Math.floor(65536 * (1 + Math.random()))
        .toString(16)
        .substring(1);
    }
    return (
      e() + e() + "-" + e() + "-" + e() + "-" + e() + "-" + e() + e() + e()
    );
  },
  currentTimestamp() {
    return (new Date() / 1000) | 0;
  },
  getEnv() {
    let env = "local";
    if (window.location.href.includes("staging") || window.location.href.includes("dev")) {
      env = "staging";
    } else if (
      window.location.href.includes("my.reporto.co.il") ||
      window.location.href.includes("app.reporto.co.il")
    ) {
      env = "production";
    }
    return env;
  },
  stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  },
  stripHtmlTags(string = "") {
    if (!string) {
      return null;
    }
    if (typeof string !== "string") {
      return string;
    }
    string = string.replace(/<(.|\n)*?>/g, "");
    string = string.replace(/&nbsp;/g, " ");
    return string;
  },
  getDeviceId() {
    let cookieDeviceId = getCookie("app_device_id");
    if (!cookieDeviceId) {
      cookieDeviceId =
        "reportoWebApp__" +
        Utils.guid() +
        "__" +
        navigator.userAgent.split(" ").join("-");

      setCookie("app_device_id", cookieDeviceId, 365);
    }
    return cookieDeviceId;
  },
  isImpersonateMode() {
    return getCookie("impersonate2_token");
  },
  getToken() {
    if (getCookie("impersonate2_token")) {
      return getCookie("impersonate2_token");
    } else {
      return getCookie("token");
    }
  },
  convertDatetimeToDisplayDateFormat(datetime) {
    return moment(datetime).format(appDateFormat);
  },
  daysLeft(date) {
    return Math.floor(
      moment.duration(moment(date).diff(moment().startOf("day"))).asDays()
    );
  },
  addFileToBase64Cache(fileBase64, fileUploaded) {
    fileBase64 = Utils.shortenBase64(fileBase64);

    if (!window.VueBase64FileCache) {
      window.VueBase64FileCache = {};
    }

    window.VueBase64FileCache[fileBase64] = fileUploaded;
  },
  isDesktop() {
    return window.innerWidth > 1100;
  },
  isFileInBase64Cache(fileBase64) {
    fileBase64 = Utils.shortenBase64(fileBase64);

    if (!window.VueBase64FileCache) {
      window.VueBase64FileCache = {};
    }

    if (window.VueBase64FileCache[fileBase64]) {
      return window.VueBase64FileCache[fileBase64];
    }

    return false;
  },
  shortenBase64(base64) {
    return (
      base64.substring(0, 40) +
      base64.substring(
        Math.ceil(base64.length / 2),
        Math.ceil(base64.length / 2) + 40
      ) +
      base64.substring(base64.length - 40)
    );
  },
  async uploadImageBase64(fileBase64, fileNamePrefix = "") {
    const cachedFile = Utils.isFileInBase64Cache(fileBase64);
    if (cachedFile) {
      return cachedFile;
    }

    // Perform the upload
    const fd = new FormData();
    fd.append("file", fileBase64);
    if (fileNamePrefix) {
      fd.append("fileNamePrefix", fileNamePrefix);
    }

    const response = await window.axios.post(`/api/v1/media/image`, fd, {
      timeout: 190000,
    });

    Utils.addFileToBase64Cache(fileBase64, { data: response.data });
    return response;
  },
  async uploadFileBase64(base64, extension, name) {
    // Let's cache base64
    if (Utils.isFileInBase64Cache(base64)) {
      return new Promise((resolve) => {
        resolve(Utils.isFileInBase64Cache(base64));
      });
    }

    // If not in cache, let's upload it.
    var fd = new FormData();
    fd.append("file", base64);
    fd.append("extension", extension);
    fd.append("name", name);

    return new Promise((resolve, reject) => {
      ``
      window.axios
        .post(`/api/v1/upload-file`, fd, { timeout: 190000 })
        .then((response) => {

          Utils.addFileToBase64Cache(base64, { data: response.data });
          resolve(response);
        })
        .catch((err) => {
          console.log("Error File Upload", err);
          reject(err);
        });
    });
  },
  checkBase64Type(str) {
    if (Utils.isBase64(str)) {
      if (Utils.isBase64Image(str)) {
        return "image";
      } else {
        return "not-image";
      }
    } else {
      return "not base64";
    }
  },
  isBase64(str) {
    return str.length > 50 && str.includes("data:");
  },
  isBase64Image(str) {
    if (!str || typeof str !== "string" || str.length < 50) {
      return false;
    }
    const mimeType = str.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mimeType && mimeType.length === 2) {
      return mimeType[1].startsWith("image/");
    }
    return false;
  },
  async uploadImage(file) {
    var fd = new FormData();
    fd.append("file", file);
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/api/v1/upload-image`, fd, { timeout: 190000 })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  hexToRgbA(hex, alpha = 1) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        alpha +
        ")"
      );
    }
    throw new Error("Bad Hex");
  },
  getColorBrightness(colorVal) {
    if (!colorVal) {
      return 0;
    }

    let color = "" + colorVal,
      isHEX = colorVal.indexOf("#") === 0,
      isRGB = colorVal.indexOf("rgb") === 0;

    let m, r, g, b;

    if (isHEX) {
      m = color.substr(1).match(color.length === 7 ? /(\S{2})/g : /(\S{1})/g);
      if (m) r = parseInt(m[0], 16);
      g = parseInt(m[1], 16);
      b = parseInt(m[2], 16);
    }
    if (isRGB) {
      m = color.match(/(\d+){3}/g);
      if (m) r = m[0];
      g = m[1];
      b = m[2];
    }
    if (typeof r != "undefined") return (r * 299 + g * 587 + b * 114) / 1000;
  },
  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  },
  getRoleHebrewName(role) {
    if (typeof role == "number" && role === 3) {
      return "משתמש";
    } else if (typeof role == "number" && role === 2) {
      return "מנהל חברה";
    } else if (typeof role == "number" && role === 4) {
      return "בעלים חברה";
    } else if (typeof role == "string" && role.toLowerCase() === "user") {
      return "משתמש";
    } else if (typeof role == "string" && role.toLowerCase() === "admin") {
      return "מנהל חברה";
    } else if (typeof role == "string" && role.toLowerCase() === "owner") {
      return "בעלים חברה";
    }
    return "אחר";
  },
  replaceAll(string, search, replacement) {
    return string.split(search).join(replacement);
  },
  getStringSizeInKb(string) {
    const b = string.length * 2;
    return (b / 1024).toFixed(2);
  },
  translate(str) {
    let words = {
      creditCard: "כרטיס אשראי",
      bankTransfer: "העברה בנקאית",
    };
    return words[str];
  },
  getImageUrl(path) {
    if (path.length > 50) {
      return path;
    }
    return env.VITE__MEDIA_URL + path;
  },
  getMediaUrl(path) {
    if (!path) {
      return null;
    }
    if (path && path.length > 150) {
      return path;
    }
    return env.VITE__MEDIA_URL + path;
  },
  copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Copying text command was " + msg);
    } catch (err) {
      console.log("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  },
  isObjectEmpty(objectName) {
    return JSON.stringify(objectName) === "{}";
  },
  flatAndMergeObjects(obj1, obj2) {
    let obj1Flatten = Utils.flattenObject(obj1);
    let obj2Flatten = Utils.flattenObject(obj2);

    return Utils.UnFlattenObject({ ...obj1Flatten, ...obj2Flatten });
  },
  flattenObject(obj) {
    const flattened = {};

    function flatten(currentObj, key) {
      if (typeof currentObj !== "object" || currentObj === null) {
        flattened[key] = currentObj;
        return;
      }

      for (const [propKey, propValue] of Object.entries(currentObj)) {
        const newKey = key ? `${key}.${propKey}` : propKey;
        flatten(propValue, newKey);
      }
    }

    flatten(obj, "");

    return flattened;
  },
  UnFlattenObject(obj) {
    const result = {};

    // For each object path (property key) in the object
    for (const objectPath in obj) {
      const parts = objectPath.split(".");
      let target = result;

      // Create sub-objects along path as needed
      while (parts.length > 1) {
        const part = parts.shift();
        const nextPartIsArray = /^\d+$/.test(parts[0]);

        if (!target[part]) {
          // If it doesn't exist, create an array or object based on the next part
          target[part] = nextPartIsArray ? [] : {};
        } else if (Array.isArray(target[part]) && !nextPartIsArray) {
          // If it's an array but the next part should be an object, convert it
          target[part] = Object.assign(
            {},
            ...target[part].map((item, index) => ({ [index]: item }))
          );
        } else if (!Array.isArray(target[part]) && nextPartIsArray) {
          // If it's an object but the next part should be an array, convert it
          target[part] = Object.values(target[part]);
        }

        target = target[part];
      }

      // Set the final property, checking that target is still an object
      if (typeof target === 'object' && target !== null) {
        target[parts[0]] = obj[objectPath];
      }
    }

    return result;
  },

  randomString(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  presentHebrewDate(dateStr) {
    const date = new Date(dateStr);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formatter = new Intl.DateTimeFormat("he-IL", options);

    return formatter.format(date);
  },
};

export const StockUtils = {
  getStockCategories(stock) {
    if (!stock || !stock.data || !stock.data.categories) {
      return [];
    }
    return stock.data.categories;
  },
  getStockLocations(stock) {
    if (!stock || !stock.data || !stock.data.locations) {
      return [];
    }
    return stock.data.locations;
  },
  getStockNodes(stock) {
    let stockCategories = this.getStockCategories(stock);
    let nodes = [];

    stockCategories.forEach((stockCategory) => {
      if (!stockCategory.nodes) {
        return;
      }
      nodes = nodes.concat(stockCategory.nodes);
    });

    return nodes;
  },
  getCategoryByName(stock, categoryName) {
    let stockCategories = this.getStockCategories(stock);

    let categoryFound = false;

    stockCategories.forEach((stockCategory) => {
      if (stockCategory.name === categoryName.trim()) {
        categoryFound = stockCategory;
      }
    });

    return categoryFound;
  },
  getNodeByName(stock, nodeName) {
    let stockCategories = this.getStockCategories(stock);

    let nodeFound = false;

    stockCategories.forEach((category) => {
      if (!category.nodes) {
        return;
      }

      category.nodes.forEach((node) => {
        if (nodeName && node.name === nodeName.trim()) {
          nodeFound = node;
        }
      });
    });

    return nodeFound;
  },
  getNodeCategoryByName(stock, nodeName) {
    let stockCategories = this.getStockCategories(stock);

    let nodeCategoryFound = false;

    stockCategories.forEach((category) => {
      if (!category.nodes) {
        return;
      }

      category.nodes.forEach((node) => {
        if (node.name === nodeName.trim()) {
          nodeCategoryFound = category;
        }
      });
    });

    return nodeCategoryFound;
  },
  getNodeCategoriesByName(stock, nodeName) {
    let stockCategories = this.getStockCategories(stock);

    let nodeCategoriesFound = [];

    stockCategories.forEach((category) => {
      if (!category.nodes) {
        return;
      }

      category.nodes.forEach((node) => {
        if (node.name === nodeName.trim()) {
          nodeCategoriesFound.push(category);
        }
      });
    });

    return nodeCategoriesFound;
  },
};

export const formRules = {
  required: [(value) => !!value || "שדה חובה"],
  email: [
    (v) => !!v || "חובה למלא מייל",
    (v) => /.+@.+\..+/.test(v) || "מייל לא תקין",
  ],
};

export const DefectsUtils = {
  orderDefectsByGroup(groupType, defects) {
    let orderedByGroup = defects.reduce(function (r, a) {
      r[a[groupType]] = r[a[groupType]] || [];
      r[a[groupType]].push(a);
      return r;
    }, Object.create(null));

    let newDefectsOrdered = [];

    for (let key in orderedByGroup) {
      if (Object.prototype.hasOwnProperty.call(orderedByGroup, key)) {
        newDefectsOrdered = newDefectsOrdered.concat(orderedByGroup[key]);
      }
    }

    return newDefectsOrdered;
  },
  getDefectsIds(defects) {
    return defects.map((defect) => {
      return defect.id;
    });
  },
  getGroups(groupType, defects) {
    if (!defects) {
      return [];
    }

    let defectsArray = Object.values(defects).sort((a, b) => a.order - b.order);

    let groups = [];

    defectsArray.forEach((defect) => {
      let group = defect[groupType];
      if (!groups.includes(group)) {
        groups.push(group);
      }
    });

    return groups;
  },
  moveGroups(groupType, defects, groupName, moveToBelowThisGroupName) {
    if (!defects) {
      return [];
    }

    let oldIndex = null;
    let newIndex = null;

    for (let i = 0; i < defects.length; i++) {
      if (defects[i][groupType] === groupName) {
        oldIndex = i;
        break;
      }
    }

    for (let i = 0; i < defects.length; i++) {
      if (defects[i][groupType] === moveToBelowThisGroupName) {
        newIndex = i;
        break;
      }
    }

    let reorderedOnce = Utils.arrayMove(defects, oldIndex, newIndex);

    let reorederedAll = DefectsUtils.orderDefectsByGroup(
      groupType,
      reorderedOnce
    );

    return reorederedAll;
  },
  isNodeInSearch(node, searchQuery = null) {
    return (
      !searchQuery ||
      (node.description && node.description.includes(searchQuery)) ||
      (node.note && node.note.includes(searchQuery)) ||
      (node.standard && node.standard.includes(searchQuery)) ||
      (node.recommendation && node.recommendation.includes(searchQuery)) ||
      (node.default_defect_note &&
        node.default_defect_note.includes(searchQuery)) ||
      (node.default_defect_standard &&
        node.default_defect_standard.includes(searchQuery)) ||
      (node.default_defect_recommendation &&
        node.default_defect_recommendation.includes(searchQuery))
    );
  },
  totalValidDefectsInGroup(groupType, defects, groupName, searchQuery = null) {
    let total = 0;
    if (defects) {
      for (const [, defect] of Object.entries(defects)) {
        if (
          DefectsUtils.isNodeInSearch(defect, searchQuery) &&
          !defect.deletedAt &&
          (defect[groupType] === groupName ||
            (!defect[groupType] && groupName === "ללא מיקום"))
        ) {
          total++;
        }
      }
    }
    return total;
  },
  sortDefectsOrderByCategories(groupType, obj, categories) {
    let items = [];
    for (let item in obj) {
      items.push(obj[item]);
    }
    items.sort(
      (a, b) =>
        categories.indexOf(a[groupType]) - categories.indexOf(b[groupType])
    );
    for (let i = 0; i < items.length; i++) {
      items[i].order = i + 1;
    }
    let sortedObj = {};
    for (let i = 0; i < items.length; i++) {
      sortedObj[items[i].id] = items[i];
    }
    return sortedObj;
  },
  reorderDefects(defects, oldIndex, newIndex) {
    defects = Object.values(defects).sort((a, b) => a.order - b.order);

    let defectsAfterMove = Utils.arrayMove(defects, oldIndex, newIndex);

    let order = 1;
    defectsAfterMove.forEach((defect, defectIndex) => {
      defectsAfterMove[defectIndex].order = order;
      order++;
    });

    let sortedObj = {};
    for (let i = 0; i < defectsAfterMove.length; i++) {
      sortedObj[defectsAfterMove[i].id] = defectsAfterMove[i];
    }
    return sortedObj;
  },
};

export const ItemsUtils = {
  getItemType(type) {
    let itemTypeFound = false;

    reportTemplateItemTypes.forEach((itemType) => {
      if (itemType.type === type) {
        itemTypeFound = JSON.parse(JSON.stringify(itemType));
      }
    });

    return itemTypeFound;
  },
  getItemTypeIcon(type) {
    let itemType = this.getItemType(type);
    if (itemType && itemType.icon) {
      return "mdi-" + itemType.icon;
    }
    return "mdi-format-title";
  },
  getItemTypes(supports = null) {
    if (!supports) {
      return reportTemplateItemTypes;
    }

    return reportTemplateItemTypes.filter((itemType) => {
      return itemType.supports.includes(supports);
    });
  },
  getTotalValidItems(items) {
    let total = 0;
    for (const [, item] of Object.entries(items)) {
      if (item && !item.deletedAt) {
        total++;
      }
    }
    return total;
  },
};

export const getRoleName = (roleId) => {
  let roleName = null;
  if (roleId === 2) {
    roleName = "admin";
  }
  if (roleId === 3) {
    roleName = "user";
  }
  if (roleId === 4) {
    roleName = "owner";
  }

  return roleName;
};

export const isHEIC = (file) => {
  // check file extension since windows returns blank mime for heic
  let x = file.type
    ? file.type.split("image/").pop()
    : file.name.split(".").pop().toLowerCase();
  return x == "heic" || x == "heif";
};

export const loadScript = (url, callback) => {
  if (!window.scriptsLoaded) {
    window.scriptsLoaded = [];
  }
  if (window.scriptsLoaded.includes(url)) {
    callback();
    return;
  }
  var script = document.querySelectorAll("script");
  for (var i = 0; i < script.length; i++) {
    if (script[i].src === url) {
      script = script[i];
      if (!script.readyState && !script.onload) {
        callback();
      } else {
        // script not loaded so wait up to 10 seconds
        var secs = 0,
          thisInterval = setInterval(function () {
            secs++;
            if (!script.readyState && !script.onload) {
              clearInterval(thisInterval);
              callback();
            } else if (secs == 10) {
              clearInterval(thisInterval);
              console.log("could not load " + url);
            }
          }, 1000);
      }
      return;
    }
  }
  script = document.createElement("script");
  script.type = "text/javascript";
  document.getElementsByTagName("head")[0].appendChild(script);
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        window.scriptsLoaded.push(url);
        callback();
      }
    };
  } else {
    script.onload = function () {
      script.onload = null;
      window.scriptsLoaded.push(url);
      callback();
    };
  }
  script.src = url;
};

export const convertFileIfHeicToJpg = (file) => {
  return new Promise(function (resolve) {
    if (!isHEIC(file)) {
      return resolve(file);
    }
    loadScript(
      "https://cdn.jsdelivr.net/npm/heic2any@0.0.3/dist/heic2any.min.js",
      function () {
        // eslint-disable-next-line no-undef
        heic2any({
          blob: file,
          toType: "image/jpg",
        }).then(function (convertedFile) {
          convertedFile.name =
            file.name.substring(0, file.name.lastIndexOf(".")) + ".jpeg";
          resolve(convertedFile);
        });
      }
    );
  });
};

// Retrying  with backoff ->  Do not overflud server.
export async function retryWithBackoff(fn, retries = 5, delay = 1000) {
  let attempt = 0;
  while (attempt < retries) {
    try {
      return await fn();
    } catch (err) {
      attempt++;
      const backoffDelay = delay * Math.pow(2, attempt);
      await new Promise((res) => setTimeout(res, backoffDelay)); // Wait before retrying
    }
  }
  throw new Error("Max retries exceeded");
}

export async function getLogData() {
  try {

    // Get the current route and other relevant app state
    const currentRoute = window.location.pathname;

    // Using the memoryUsage -> Understanding if it crashes due to memory issues
    const memoryUsage = window.performance.memory
      ? {
        usedJSHeapSize: window.performance.memory.usedJSHeapSize,
        totalJSHeapSize: window.performance.memory.totalJSHeapSize,
      }
      : "Memory data not available";

    // Collect storage data
    let storageInfo
    if (navigator?.storage?.estimate) {

      const storageEstimate = await navigator.storage.estimate();
      storageInfo = storageEstimate
        ? {
          usage: (storageEstimate.usage / (1024 * 1024)).toFixed(2) + " MB",
          quota: (storageEstimate.quota / (1024 * 1024)).toFixed(2) + " MB",
        }
        : "Storage data not available";
    }

    // Collect connection data
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    const networkInfo = connection
      ? {
        type: connection.effectiveType,
        downlink: connection.downlink,
        rtt: connection.rtt,
      }
      : "Network data not available";

    return {
      currentRoute,
      memoryUsage,
      storageInfo,
      networkInfo,
    };
  } catch (err) {
    console.error('err', err)
    return {
      unknownError: "asdkjhsadhsjkd"
    }
  }
}
