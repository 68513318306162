<template>
  <div>
    <RFieldText
      v-model="localItem.settings.headline"
      label="כותרת"
      class="mb-3"
    />

    <RFieldAlign
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <RFieldSelect
      v-model="localItem.settings.style"
      class="mb-3"
      label="עיצוב בPDF"
      :items="[
        { text: 'טבלה', value: 'table' },
        { text: 'רשימה', value: 'list' },
      ]"
    />

    <RFieldSwitch
      v-if="localItem.settings.style === 'list'"
      v-model="localItem.settings.pageBreakBetweenRows"
      label="שבירת עמוד בין שורות"
    />

    <RFieldSwitch
      v-model="localItem.settings.withIndexColumn"
      label="הוספת עמודת מספור"
    />
    <RAlert
      v-if="columnsTotalWidth !== 100 && localItem.settings.style !== 'list'"
      type="error"
    >
      שגיאה: סך העמודות חייב להיות בדיוק 100%
    </RAlert>

    <draggable
      :list="localItem.settings.columns"
      handle=".list-drag"
      v-bind="dragOptions"
      itemKey="id"
    >
      <template #item="{ element: column, index: columnId }">
        <div
          :key="columnId"
          style="border: thin solid rgba(0, 0, 0, 0.12); background-color: #fff"
          class="mb-2 px-2 py-3 rounded d-flex align-center"
        >
          <RIcon class="list-drag u-cursor-move">mdi-drag</RIcon>
          <RIcon>{{ getItemTypeIcon(column.type) }}</RIcon>

          <div class="mr-2 u-flex-1 d-flex align-center">
            <input
              v-model="column.settings.label"
              type="text"
              placeholder="טקסט"
              class="m-1"
              style="flex: 1; width: 150px"
            />
            <span v-if="localItem.settings.style !== 'list'">
              %
              <input
                v-model="column.settings.columnWidth"
                type="number"
                placeholder="רוחב"
                class="ml-1"
                style="width: 50px"
              />
            </span>
          </div>

          <RButtonIcon icon="mdi-cog" @click="openTableColumnModal(column)" />
        </div>
      </template>
    </draggable>

    <RButton
      color="grey"
      class="mx-auto d-block"
      icon-before="mdi-plus"
      @click="isAddItemModalOpen = true"
    >
      הוספת עמודה
    </RButton>

    <RModal v-model="isAddItemModalOpen" title="בחירת סוג עמודה">
      <div>
        <RButton
          v-for="(itemType, itemTypeId) in getItemTypes('table')"
          :key="itemTypeId"
          color="primary"
          class="ma-1 mx-1"
          :icon-before="getItemTypeIcon(itemType.type)"
          @click="addTableColumn(itemType.type)"
        >
          {{ itemType.name }}
        </RButton>
      </div>
    </RModal>

    <RModal v-model="tableColumnModal.isOpen" title="עריכת עמודה">
      <RFields>
        <RFieldText
          v-model="tableColumnModal.item.settings.label"
          label="שם עמודה"
        />

        <RFieldSwitch
          v-model="tableColumnModal.item.settings.showLabelOnReport"
          label="הצג שם עמודה בדוח"
        />

        <FieldsImages
          v-if="tableColumnModal.item.type === 'images'"
          v-model="tableColumnModal.item"
          :edit-content="false"
        />

        <div v-if="tableColumnModal.item.type === 'textChoices'">
          <RFieldCheckbox
            v-model="tableColumnModal.item.settings.multiple"
            class="mb-2"
            label="אפשר בחירה מרובה"
          />
          <label>אפשרויות</label>

          <draggable
            :list="tableColumnModal.item.settings.options"
            handle=".list-drag"
            v-bind="dragOptions"
            item-key="id"
          >
            <template #item="{ element: option }">
              <div
                style="
                  border: thin solid rgba(0, 0, 0, 0.12);
                  background-color: #fff;
                "
                class="mb-2 px-2 py-3 rounded d-flex align-center"
              >
                <RIcon class="list-drag u-cursor-move">mdi-drag</RIcon>

                <div class="u-flex-1 d-flex">
                  <input
                    v-model="option.label"
                    placeholder="הכנס טקסט לבחירה"
                    type="text"
                    class="tableColumnOptions-input ml-5"
                    style="flex: 1"
                  />
                  <AppFieldColorPicker v-model="option.color" />
                </div>

                <RButtonIcon
                  class="mr-3"
                  icon="mdi-delete"
                  @click="removeOption(tableColumnModal.item, option.id)"
                />
              </div>
            </template>
          </draggable>
          <RButton
            color="primary"
            block
            lg
            icon-before="mdi-plus"
            @click="addOption(tableColumnModal.item)"
          >
            הוספת אפשרות
          </RButton>
        </div>

        <RButton
          color="red"
          outlined
          class="mt-4"
          sm
          icon-before="mdi-delete"
          @click="removeItemTable(tableColumnModal.item.id)"
        >
          מחיקת עמודה
        </RButton>
        <RButton
          class="mt-4"
          color="primary"
          lg
          block
          @click="submitTableItemModal"
        >
          שמירה
        </RButton>
      </RFields>
    </RModal>

    <br />

    <report-table
      v-if="editContent"
      label="תוכן הטבלה"
      :load-rows="localItem.settings.rows"
      :columns="localItem.settings.columns"
      update-key="rows"
      @do-update="doUpdateTable"
      @do-update-many="doUpdateManyTable"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { Utils } from "@/common/Utils";
import { dragOptions, reportTemplateItemTypes } from "@/common/settings";
import FieldsImages from "./FieldsImages";
import ReportTable from "@/views/reports/components/ReportTable.vue";
import { mapGetters } from "vuex";
import AppFieldColorPicker from "@/components/ReportTemplate/components/AppFieldColorPicker.vue";

export default {
  components: {
    ReportTable,
    draggable,
    FieldsImages,
    AppFieldColorPicker,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
      dragOptions,
      itemTypes: reportTemplateItemTypes,
      isAddItemModalOpen: false,
      tableColumnModal: {
        isOpen: false,
        item: {
          settings: {
            showLabelOnReport: true,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["currentCompanyId"]),
    columnsTotalWidth() {
      let sum = 0;

      this.localItem.settings.columns.forEach((column) => {
        if (column.settings.columnWidth) {
          sum = sum + Number(column.settings.columnWidth);
        }
      });

      return sum;
    },
  },
  watch: {
    localItem: {
      handler(val) {
        this.$emit("update:modelValue", val);
      },
      deep: true,
    },
    "localItem.settings.style": function (newStyle) {
      if (newStyle === "list") {
        this.localItem.settings.columns.forEach((column, columnIndex) => {
          this.localItem.settings.columns[
            columnIndex
          ].settings.showLabelOnReport = true;
        });
      }
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    getItemTypeIcon(type) {
      let itemType = this.getItemType(type);
      if (itemType && itemType.icon) {
        return "mdi-" + itemType.icon;
      }
      return "mdi-format-title";
    },
    updateParentValue(val) {
      this.localItem = val;
    },
    addTableColumn(type) {
      this.isAddItemModalOpen = false;
      let itemType = this.getItemType(type);

      // Manually if it's inside repeater or table
      if (type === "images") {
        itemType.settings.imagesPerRow = 1;
      }

      let newItem = {
        id: Utils.guid(),
        type: type,
        settings: {
          ...itemType.settings,
          columnWidth: 10,
          showLabelOnReport: true,
        },
      };

      this.localItem.settings.columns.push(newItem);
    },
    addOption(item) {
      item.settings.options.push({
        id: Utils.guid(),
        label: "",
        color: "#ffffff",
      });
    },
    removeOption(item, optionId) {
      item.settings.options = item.settings.options.filter((option) => {
        return option.id !== optionId;
      });
    },
    openTableColumnModal(item) {
      this.tableColumnModal.item = JSON.parse(JSON.stringify(item));
      this.tableColumnModal.isOpen = true;
    },
    closeTableColumnModal() {
      this.tableColumnModal.isOpen = false;
    },
    submitTableItemModal() {
      this.localItem.settings.columns.forEach((itemLoop) => {
        if (itemLoop.id === this.tableColumnModal.item.id) {
          itemLoop.settings = Object.assign(
            {},
            this.tableColumnModal.item.settings
          );
          itemLoop.settings.label = this.tableColumnModal.item.settings.label;
        }
      });

      this.closeTableColumnModal();
    },
    removeItemTable(itemId) {
      if (confirm("בטוח למחוק את השדה?")) {
        this.localItem.settings.columns =
          this.localItem.settings.columns.filter((item) => {
            return item.id !== itemId;
          });

        this.closeTableColumnModal();
      }
    },

    getItemTypes(supports = false) {
      if (!supports) {
        return this.itemTypes;
      }

      return this.itemTypes.filter((itemType) => {
        return itemType.supports.includes(supports);
      });
    },
    getItemType(type) {
      let itemTypeFound = false;

      this.itemTypes.forEach((itemType) => {
        if (itemType.type === type) {
          itemTypeFound = JSON.parse(JSON.stringify(itemType));
        }
      });

      return itemTypeFound;
    },
    async doUpdateTable({ key, value }) {
      let cleanData = JSON.parse(
        JSON.stringify({
          [key]: value,
        })
      );

      let dataUploaded = await this.uploadObjectImages(cleanData);

      let settingsNew = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );

      let settingsOld = this.localItem.settings;

      this.localItem.settings.rows = Utils.flatAndMergeObjects(
        settingsOld.rows,
        settingsNew.rows
      );
    },
    async doUpdateManyTable({ data }) {
      let cleanData = JSON.parse(JSON.stringify(data));

      let dataUploaded = await this.uploadObjectImages(cleanData);

      let settingsNew = Utils.flatAndMergeObjects(
        this.localItem.settings,
        dataUploaded
      );

      let settingsOld = this.localItem.settings;

      this.localItem.settings.rows = Utils.flatAndMergeObjects(
        settingsOld.rows,
        settingsNew.rows
      );
    },
    async uploadObjectImages(obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (value && Utils.isBase64Image(value)) {
          let loader = this.$loading.show();
          let { data } = await Utils.uploadImageBase64(value);
          loader.hide();
          obj[key] = data.path;
        }
      }

      return obj;
    },
  },
};
</script>
