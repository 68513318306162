import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "vue-toastification/dist/index.css";
import { LoadingPlugin } from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';
import CheckRole from "./mixins/CheckRole";
import { ItemsUtils, Utils } from "@/common/Utils";
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginVue from '@bugsnag/plugin-vue'
import axios from "axios";
import { env } from "@/services/EnvService";
import { appVersion } from "@/common/appVersion";
import posthogPlugin from "./plugins/posthog";
import toast from "./plugins/toast";
import * as Sentry from "@sentry/vue";
import './assets/index.css' // tailwind
import "./assets/sass/app.scss"; // app

// Import previously async components
import RAction from "./ui/RAction";
import RActions from "./ui/RActions";
import RSimpleCard from "./ui/RSimpleCard";
import RAlert from "./ui/RAlert";
import RButton from "./ui/RButton";
import RButtonIcon from "./ui/RButtonIcon";
import RModal from "./ui/RModal";
import RSelectList from "./ui/RSelectList";
import RChip from "./ui/RChip";
import RSearchCompact from "./ui/RSearchCompact";
import RFields from "./ui/RFields";
import RFieldText from "./ui/RFieldText";
import RFieldSearch from "./ui/RFieldSearch";
import RFieldTextChoices from "./ui/RFieldTextChoices";
import RFieldSelect from "./ui/RFieldSelect";
import RFieldSelectMultiple from "./ui/RFieldSelectMultiple";
import RFieldCheckbox from "./ui/RFieldCheckbox";
import RFieldRadio from "./ui/RFieldRadio";
import RFieldDatePicker from "./ui/RFieldDatePicker";
import RIcon from "./ui/RIcon";
import RIconLoading from "./ui/RIconLoading";
import RFieldAlign from "@/ui/RFieldAlign.vue";
import RFieldImage from "@/ui/RFieldImage.vue";
import RFieldSignature from "@/ui/RFieldSignature.vue";
import RFieldSwitch from "@/ui/RFieldSwitch";
import RFieldWysiwyg from "@/ui/RFieldWysiwyg.vue";

import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import VueKonva from "vue-konva";

// Axios Configuration
window.axios = axios;
window.axios.defaults.baseURL = env.VITE__API_URL;
window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "X-App-Version": appVersion,
};

window.axios.interceptors.request.use(
  (request) => {
    const token = store.getters["auth/token"];
    if (token) {
      request.headers = request.headers || {};
      request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
  }
);

// window.axios.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );

// Start Bugsnag before app initialization
// if (Utils.getEnv() !== "local") {
//   Bugsnag.start({
//     appVersion: appVersion,
//     apiKey: "5be55d0bcbce27c89626920345993d3d",
//     plugins: [new BugsnagPluginVue()],
//     releaseStage: Utils.getEnv(),
//   });
// }

// Create the app instance
const app = createApp(App);

// Attach Bugsnag’s Vue plugin
// if (Utils.getEnv() !== "local") {
//   const bugsnagVue = Bugsnag.getPlugin('vue');
//   if (bugsnagVue) {
//     bugsnagVue.installVueErrorHandler(app);
//     window.Bugsnag = Bugsnag;
//   }
// }

// Add Sentry
if (Utils.getEnv() === "production") {
  console.log("Sentry loaded");
  Sentry.init({
    app,
    dsn: "https://46e568cfcd993936be9e882a2c252153@o4506404248420352.ingest.us.sentry.io/4507186623873024",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// Global Properties
app.config.globalProperties.$Utils = Utils;
app.config.globalProperties.$ItemsUtils = ItemsUtils;
app.config.globalProperties.$Reporto = {
  globals: {
    API_URL: env.VITE__API_URL,
    MEDIA_URL: env.VITE__MEDIA_URL,
    ROLES: { 2: "Admin", 3: "User", 4: "Owner" },
  },
};

// Register previously async components
app.component("RAction", RAction);
app.component("RActions", RActions);
app.component("RSimpleCard", RSimpleCard);
app.component("RAlert", RAlert);
app.component("RButton", RButton);
app.component("RButtonIcon", RButtonIcon);
app.component("RModal", RModal);
app.component("RSelectList", RSelectList);
app.component("RChip", RChip);
app.component("RSearchCompact", RSearchCompact);
app.component("RFields", RFields);
app.component("RFieldText", RFieldText);
app.component("RFieldSearch", RFieldSearch);
app.component("RFieldTextChoices", RFieldTextChoices);
app.component("RFieldSelect", RFieldSelect);
app.component("RFieldSelectMultiple", RFieldSelectMultiple);
app.component("RFieldCheckbox", RFieldCheckbox);
app.component("RFieldRadio", RFieldRadio);
app.component("RFieldDatePicker", RFieldDatePicker);
app.component("RIcon", RIcon);
app.component("RIconLoading", RIconLoading);
app.component("RFieldAlign", RFieldAlign);
app.component("RFieldImage", RFieldImage);
app.component("RFieldSignature", RFieldSignature);
app.component("RFieldSwitch", RFieldSwitch);
app.component("RFieldWysiwyg", RFieldWysiwyg);



// Mixins
app.mixin(CheckRole);


// Use Plugins
app.use(vuetify);
app.use(router);
app.use(store);
app.use(CkeditorPlugin)

app.use(VueKonva);
app.use(LoadingPlugin, {
  loader: 'dots',
  color: "#5D21D2",
  backgroundColor: "#463b6eb3",
  opacity: 0.5,
  lockScroll: true,
  canCancel: false,
  width: 64,
  height: 64,
  isFullPage: true,
  message: "טוענים, כמה רגעים וסיימנו.."
});

app.use(toast);
app.use(posthogPlugin);
// Mount the app
app.mount("#app");
