<template>
  <div>
    <RFieldSelect
      v-model="localItem.settings.style"
      label="עיצוב"
      class="mb-5"
      :items="[
        { text: 'טבלה', value: 'table' },
        { text: 'רשימה', value: 'list' },
      ]"
    />

    <RFieldSelect
      v-model="localItem.settings.uploadsPerRow"
      class="mb-5"
      label="מספר תמונות בשורה"
      :items="[
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
        { text: 4, value: 4 },
      ]"
    />

    <RFieldSelect
      v-model="localItem.settings.attachmentsPerRow"
      class="mb-8"
      label="מספר נספחים בשורה"
      :items="[
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
        { text: 4, value: 4 },
      ]"
    />

    <v-list class="pa-0 bg-transparent">
      <draggable
        v-model="localItem.settings.columns"
        handle=".v-avatar"
        itemKey="id"
        v-bind="dragOptions"
      >
        <template #item="{ element: column }">
          <v-list-item
            :key="column.id"
            dense
            style="border: thin solid rgba(0, 0, 0, 0.12)"
            class="mb-2 rounded bg-white"
          >
            <div class="d-flex align-center">
              <v-avatar>
                <v-icon> mdi-drag</v-icon>
              </v-avatar>

              <v-text-field
                v-model="column.text"
                :label="column.name"
                hide-details
                density="compact"
                variant="outlined"
              ></v-text-field>

              <v-switch
                v-model="column.isActive"
                label="פעיל"
                color="var(--color-green)"
                hide-details
                dense
                inset
                flat
                class="mt-0 ml-0 mr-3"
                :ripple="false"
              ></v-switch>
            </div>
          </v-list-item>
        </template>
      </draggable>
    </v-list>

    <br />
    <RFieldSwitch
      v-model="localItem.settings.hideAttachments"
      label="הסתר נספחים"
      tooltip="כשזה מסומן, לא ראו את הנספחים שתעלו לליקויים בדוח עצמו"
    />

    <br />

    <div class="app-field">
      <label class="app-field__label"> בחירת שדות חובה </label>
      <div
        v-for="(fieldName, fieldKey) in defectValidationFields"
        :key="fieldKey"
      >
        <RFieldCheckbox
          :model-value="isFieldRequired(fieldKey)"
          :label="fieldName"
          @update:model-value="setFieldRequired(fieldKey, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { dragOptions } from "@/common/settings";

export default {
  components: {
    draggable,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dragOptions,
      localItem: null,
      defectValidationFields: {
        category: "פרק",
        description: "ממצא",
        defectStatus: "מצב ממצא",
        defectRecheckStatus: "מצב ממצא חוזר",
        location: "מיקום",
        recommendation: "המלצה",
        note: "הערה",
        standard: "תקן",
        unitPrice: "מחיר",
        unitType: "יחידת מידה",
        unitQuantity: "כמות",
      },
    };
  },
  watch: {
    localItem: {
      handler(val) {
        this.$emit("update:modelValue", val);
      },
      deep: true,
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
    isFieldRequired(fieldKey) {
      if (
        !this.localItem.validation ||
        !this.localItem.validation[fieldKey] ||
        !this.localItem.validation[fieldKey].required
      ) {
        return false;
      }

      return this.localItem.validation[fieldKey].required;
    },
    setFieldRequired(fieldKey, value) {
      if (!this.localItem.validation) {
        this.localItem.validation = {};
      }
      if (!this.localItem.validation[fieldKey]) {
        this.localItem.validation[fieldKey] = {};
      }

      this.localItem.validation[fieldKey].required = value;
    },
  },
};
</script>
