<template>
  <div>
    <v-card
      v-if="reports.length && !loading"
      outlined
      class="mobile-full-width"
    >
      <v-card-title class="d-flex justify-space-between">
        <div>הדוחות שלי</div>
        <AddReportButton size="sm" />
      </v-card-title>
      <div class="home-reports-table-wrapper">
        <v-data-table
          v-if="reports.length"
          :headers="headers"
          :items="reports"
          :hide-default-footer="true"
          :items-per-page="-1"
          :search="searchQuery"
          no-results-text="לא נמצאו דוחות עבור החיפוש שלך"
          class="elevation-0 home-reports-table"
          mobile-breakpoint="0"
            @click:row="goToReport"
        >
          <template #[`item.created_at`]="{ item }">
            {{ $Utils.presentHebrewDate(item.created_at) }}
          </template>
          <template #[`item.tag`]="{ item }">
            <template v-if="item.tag">{{ item.tag }}</template>
          </template>
        </v-data-table>
      </div>
      <v-card-actions>
        <RButton to="/reports" color="grey" block> לכל הדוחות </RButton>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="!reports.length && !loading"
      outlined
      class="py-12 px-5 text-center mobile-full-width"
    >
      <br />
      <img
        src="@/assets/images/cta-first-report.png"
        srcset="@/assets/images/cta-first-report@2x.png 2x"
        alt="first-report"
        style="pointer-events: none; max-width: 100%; height: auto;margin:0 auto"
      />
      <div class="rp-h4">בואו ניצור את הדוח הראשון שלכם!</div>
      <div class="rp-p2 mt-1 mb-4">4 קליקים ויצרתם דוח 🥳</div>

      <AddReportButton />
      <br />
    </v-card>
    <v-card v-if="loading" outlined class="mobile-full-width">
      <div class="text-center px-2 py-6">
        <RIconLoading />
      </div>
    </v-card>
  </div>
</template>

<script>
import AddReportButton from "@/components/AddReportButton.vue";
import ReportsApi from "@/api/ReportsApi";

export default {
  components: { AddReportButton },
  data() {
    return {
      loading: false,
      reports: [],
      searchQuery: null,
    };
  },
  computed: {
    headers() {
      let headers = [];
      headers.push({
        title: "מספר",
        value: "number",
        width: "85px",
        sortable: true,
      });
      headers.push({
        title: "כתובת",
        value: "address",
        sortable: true,
      });
      headers.push({
        title: "לקוח",
        value: "customerName",
        sortable: true,
      });
      headers.push({
        title: "פרויקט",
        value: "project_name",
        sortable: true,
      });
      // headers.push({
      //   title: "משתמש",
      //   value: "user_name",
      // });
      headers.push({
        title: "תווית",
        value: "tag",
        sortable: true,
      });
      headers.push({
        title: "תאריך יצירה",
        value: "created_at",
        width: "180px",
        sortable: true,
      });
      return headers;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchReports();
    this.loading = false;
  },
  methods: {
    goToReport(event, row) {
      let reportId = row.item.id;
      this.$router.push({
        name: "reports.edit",
        params: { id: reportId },
      });
    },
    async fetchReports() {
      const data = await ReportsApi.getReports({
        page: this.page,
        searchQuery: "",
      });
      this.reports = [...data.data];
    },
  },
};
</script>

<style lang="scss">
.home-reports-table {
  th:nth-child(2) {
    min-width: 180px;
  }
  th:nth-child(3) {
    min-width: 180px;
  }
  th:nth-child(4) {
    min-width: 180px;
  }
  th:nth-child(5) {
    min-width: 120px;
  }
  th:nth-child(6) {
    min-width: 100px;
  }
}
.home-reports-table-wrapper {
  max-height: 457px;
  overflow-y: auto;
  @media (max-width: 767px) {
    max-height: 265px;
  }
}
</style>
