<template>
  <div>
    <!-- Floating Status Indicator -->
    <div v-if="!hasInternet || isSyncing" class="floating-sync-indicator">
      <div class="sync-indicator" :class="{ 'offline': !hasInternet }">
        <div class="sync-spinner"></div>
        <span class="sync-text">{{ statusText }}</span>
      </div>
    </div>


    <div class="r-page-report-header">
      <div class="r-page-report-header-content">
        <div class="r-page-report-header-title">
          <span class="r-page-report-header-title-title"
          >כתובת: {{ report.address }}</span
          >
          <div class="r-page-report-header-status">
            <div
                v-if="report.tag || report.is_draft"
                class="r-page-report-header-tags"
            >
              <span v-if="report.tag" class="r-page-report-header-tag">{{
                  report.tag.trim()
                }}</span>
              <span v-if="report.is_draft" class="r-page-report-header-draft"
              >טיוטה</span
              >
            </div>
          </div>
        </div>
        <div class="r-page-report-header-subtitle">
          <span v-if="report.number"> מספר דוח: {{ report.number }}</span>
          <span v-if="report.customerName"
          >לקוח: {{ report.customerName }}</span
          >
          <span v-if="report.name">תבנית: {{ report.name }}</span>
          <span v-if="report.project_id && report.project">
            פרויקט:
            <router-link
                :to="{
                name: 'projects.edit',
                params: { id: report.project.id },
              }"
            >
              {{ report.project.name }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="r-page-report-header-actions">
        <RButton
            :disabled="!hasInternet || isSyncing"
            v-show="
            !user.permissionDisableSendReportToEmail &&
            reportId !== '00b5c320-c96e-46ee-ae56-9651ee0b3bf7'
          "
            icon-before="mdi-email"
            outlined
            color="green"
            @click="openSendModal"
        >
          שליחה למייל
        </RButton>
        <RButton
            :disabled="!hasInternet || isSyncing"
            v-show="
            !user.permissionDisableSendReportToEmail &&
            reportId !== '00b5c320-c96e-46ee-ae56-9651ee0b3bf7'
          "
            icon-before="mdi-download"
            outlined
            color="blue"
            @click="openShareReportModal"
            :loading="isDownloadingReport"
        >
          הורדה
        </RButton>

        <RActions>
          <template #activator="{ props }">
            <button
                v-bind="props"
                role="button"
                class="r-button r-button--black r-button--outlined"
            >
              פעולות נוספות
              <i
                  aria-hidden="true"
                  class="v-icon notranslate mdi mdi-dots-vertical theme--light"
              ></i>
            </button>
          </template>

          <RAction
              @click="openSettingsModal"
              icon="mdi-cog"
              text="הגדרות דוח"
          ></RAction>
          <RAction
              @click="openPreviewModal"
              icon="mdi-eye"
              text="תצוגה מקדימה"
              :disabled="!hasInternet || isSyncing"
          ></RAction>
          <RAction
              @click="openCloneModal"
              icon="mdi-content-copy"
              text="שכפול דוח"
              :disabled="!hasInternet || isSyncing"
          ></RAction>
          <RAction
              link
              :to="{
              name: 'reports.editTemplate',
              params: { id: reportId },
            }"
              icon="mdi-view-dashboard"
              text="עריכת התבנית"
              :disabled="!hasInternet || isSyncing"
          ></RAction>
        </RActions>
      </div>
    </div>

    <ReportSendModal ref="sendModalRef" :report-id="reportId" />

    <ReportSettingsModal ref="settingsModalRef" />

    <ReportCloneModal ref="cloneModalRef" :report-id="reportId" />

    <ReportPreviewModal ref="previewModalRef" :report-id="reportId" />

    <RModal v-model="isShareReportModalOpen" title="שיתוף והורדת הדוח">
      <p class="text-black/60 mb-4">
        * במידה ותעשו שינויים נוספים בדוח תצטרכו ליצור קישור חדש לשיתוף והורדה.
      </p>
      <div class="share-link-container">
        <input
          type="text"
          id="short-report-link"
          v-model="shortReportLink"
          readonly
          @click="copyReportLink"
          class="share-link-input"
          :title="'לחץ להעתקה'"
        />
        <span class="share-link-label">קישור לשיתוף הדוח</span>
      </div>

      <div class="share-report-buttons mt-3">
        <RButton
            lg
            icon-before="mdi-whatsapp"
            color="green"
            @click="shareReportLinkOnWhatsapp"
        >
          שיתוף ב-Whatsapp
        </RButton>
        <RButton
            lg
            icon-before="mdi-download"
            color="blue"
            @click="downloadReportLink"
        >
          הורדת הדוח PDF
        </RButton>
        <RButton
            lg
            icon-before="mdi-content-copy"
            color="purple"
            @click="copyReportLink"
        >
          העתקת קישור הדוח
        </RButton>
      </div>
    </RModal>
  </div>
</template>

<script setup>
import { useBackgroundSync } from '@/composables/useBackgroundSync';
import { useStore } from 'vuex';
import ReportSendModal from "./ReportSendModal";
import ReportCloneModal from "./ReportCloneModal";
import ReportPreviewModal from "./ReportPreviewModal";
import ReportsApi from "@/api/ReportsApi";
import ReportSettingsModal from "./ReportSettingsModal.vue";
import { Utils } from "@/common/Utils";
import { ref, computed } from 'vue';
import { useToast } from 'vue-toastification';


const store = useStore();
const { isSyncing, hasInternet } = useBackgroundSync();

const isCloneReportModalOpen = ref(false);
const isPreviewReportModalOpen = ref(false);
const isSettingsReportModalOpen = ref(false);
const isShareReportModalOpen = ref(false);
const shortReportLink = ref(null);
const isDownloadingReport = ref(false);
const toast = useToast();

const sendModalRef = ref(null);
const settingsModalRef = ref(null);
const cloneModalRef = ref(null);
const previewModalRef = ref(null);

// Vuex getters using composition API
const report = computed(() => store.getters['report/report']);
const user = computed(() => store.getters['auth/user']);

const reportId = computed(() => report.value.id);

// Status text for the indicator
const statusText = computed(() => {
  if (!hasInternet.value) return 'אין חיבור לאינטרנט';
  if (isSyncing.value) return 'מסנכרן...';
  return '';
});

// Methods
const openSendModal = () => {
  sendModalRef.value.openModal();
};

const openSettingsModal = () => {
  settingsModalRef.value.openModal();
};

const openCloneModal = () => {
  cloneModalRef.value.openModal();
};

const openPreviewModal = () => {
  previewModalRef.value.openModal();
};

const shareReportLinkOnWhatsapp = () => {
  if (!shortReportLink.value) {
    toast.error("שגיאה בשיתוף הדוח ל-WhatsApp");
    return;
  }

  if (window.innerWidth > 1200) {
    window.open(
      `https://api.whatsapp.com/send?text=${shortReportLink.value}`,
      "_blank"
    );
  } else {
    const anchor = document.createElement("a");
    anchor.href = `https://api.whatsapp.com/send?text=${shortReportLink.value}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
};

const downloadReportLink = () => {
  if (!shortReportLink.value) {
    toast.error("שגיאה בהורדת הדוח");
    return;
  }

  const anchor = document.createElement("a");
  anchor.href = shortReportLink.value;
  if (!Utils.isIOSDevice() && window.innerWidth > 1100) {
    anchor.target = "_blank";
  }

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const copyReportLink = () => {
  console.log("copyReportLink", shortReportLink.value)
  if (!shortReportLink.value) {
    toast.error("שגיאה בהעתקת הדוח");
    return;
  }

  navigator.clipboard.writeText(shortReportLink.value)
    .then(() => {
      toast.success("הקישור הועתק בהצלחה");
    })
    .catch(() => {
      toast.error("שגיאה בהעתקת הקישור");
    });
};

const openShareReportModal = async () => {
  isDownloadingReport.value = true;

  try {
    shortReportLink.value = await ReportsApi.getDownloadReportShortLink(reportId.value);
    isShareReportModalOpen.value = true;
  } catch (e) {
    toast.error("שגיאה בשיתוף הדוח");
  }

  isDownloadingReport.value = false;
};
</script>

<style scoped lang="scss">
.share-report-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
}
.share-report-button {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.r-page-report-header-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.floating-sync-indicator {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    bottom: 24px;
    left: 24px;
  }

  @media (max-width:767px) {
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.sync-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 10px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &.offline {
    background: #fee2e2;
    .sync-spinner {
      border-top-color: #ef4444;
    }
    .sync-text {
      color: #991b1b;
    }
  }
}

.sync-spinner {
  width: 14px;
  height: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.sync-text {
  font-size: 12px;
  color: #475569;
  font-weight: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.share-link-container {
  position: relative;
  margin: 1rem 0;
}

.share-link-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #f8fafc;
  color: #1e293b;
  transition: all 0.2s ease;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #f1f5f9;
    border-color: #cbd5e1;
  }

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
    background-color: white;
  }

  &::selection {
    background-color: #bfdbfe;
  }
}

.share-link-label {
  position: absolute;
  top: -10px;
  right: 10px;
  padding: 0 6px;
  font-size: 12px;
  color: #64748b;
  background-color: white;
  border-radius: 4px;
}

.share-link-container::after {
  content: 'לחץ להעתקה';
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #64748b;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.share-link-container:hover::after {
  opacity: 1;
}
</style>
