import {ref, readonly, computed} from 'vue';
import indexedDBService from '@/services/IndexedDBService';
import {sendLog} from '@/services/LogService';
import ReportsApi from '@/api/ReportsApi';
import {Utils} from '@/common/Utils';
import store from '@/store'; // Import Vuex store
import {useToast} from "vue-toastification";


const STORE_KEY = 'messageQueue';
const RETRY_DELAY = 3000;
const toast = useToast();

// Add new constant for localStorage key
const QUEUE_KEY = 'syncQueue';

// Shared state
const isInitialized = ref(false);
const isSyncing = ref(false);
const currentMessage = ref(null);
const syncError = ref(null);
const hasInternet = ref(true);
const syncProgress = ref({
    current: 0,
    imageProgress: {current: 0, total: 0}
});

// UI state computes
const showSync = computed(() => isSyncing.value || syncError.value);
const hasImages = computed(() => syncProgress.value.imageProgress.total > 0);
const currentImageCount = computed(() => syncProgress.value.imageProgress.current);
const totalImages = computed(() => syncProgress.value.imageProgress.total);
const syncedCount = computed(() => syncProgress.value.current);


// User state
const currentUserId = computed(() => store.state.auth.user?.id);
const currentCompanyId = computed(() => store.state.auth.user?.currentCompanyId);

function getQueueKey() {
    return `${QUEUE_KEY}_${currentUserId.value}_${currentCompanyId.value}`;
}

function getSyncQueue() {
    try {
        return JSON.parse(localStorage.getItem(getQueueKey()) || '[]');
    } catch {
        return [];
    }
}

function addToSyncQueue(messageId, timestamp) {
    const queue = getSyncQueue();
    queue.push({ messageId, timestamp });
    localStorage.setItem(getQueueKey(), JSON.stringify(queue));
}

function removeFromSyncQueue(messageId) {
    const queue = getSyncQueue().filter(item => item.messageId !== messageId);
    localStorage.setItem(getQueueKey(), JSON.stringify(queue));
}

export function useBackgroundSync() {
    let retryTimeout = null;

    async function init() {
        if (isInitialized.value) return;
        try {
            // sendLog('[SYNC] - Service initialized');
            isInitialized.value = true;
            await sync();
        } catch (err) {
            sendLog('[SYNC] [ERROR] - Failed to initialize service', { err });
        }
    }

    async function addMessage(message) {
        try {
            if (!currentUserId.value || !currentCompanyId.value) {
                throw new Error(`[SYNC] [ERROR] User or company ID not found`);
            }

            const enrichedMessage = {
                ...message,
                message_id: crypto.randomUUID(),
                created_at: Date.now(),
                user_id: currentUserId.value,
                company_id: currentCompanyId.value
            };

            // Save message in IndexedDB
            await indexedDBService.setItem(enrichedMessage.message_id, enrichedMessage);

            // Add to sync queue
            addToSyncQueue(enrichedMessage.message_id, enrichedMessage.created_at);

            // Start sync if not already running
            // if not syncing start
            if (!isSyncing.value) {
                sync();
            }


            return enrichedMessage;
        } catch (err) {
            sendLog(`[SYNC] [ERROR] Failed to add message`, { err });
            toast.error('שגיאה בשמירת הנתונים, מרענים את העמוד ונסו מחדש...');
            setTimeout(() => {
                location.reload();
            }, 3000);
            throw err;
        }
    }

    async function getNextMessageToSync() {
        const queue = getSyncQueue();
        if (queue.length === 0) return null;

        // Get oldest message first
        const nextItem = queue[0];
        const message = await indexedDBService.getItem(nextItem.messageId);
        if (!message) {
            // show error that message found on localStorage but not on indexeddb
            sendLog(`[SYNC] [ERROR] - Message not found in IndexedDB`, { messageId: nextItem.messageId });
            removeFromSyncQueue(nextItem.messageId);
            return null;
        }
        return message;
    }

    async function checkInternetConnection() {
        try {
            const isOnline = await ReportsApi.checkInternet();
            hasInternet.value = isOnline;
            return isOnline;
        } catch {
            hasInternet.value = false;
            return false;
        }
    }

    async function processMessageImages(message) {
        if (!message.data) {
            console.log(`[SYNC] - No images to process`);
            return message;
        }

        const imagesToUpload = Object.entries(message.data).reduce((images, [key, value]) => {
            if (value && Utils.isBase64Image(value)) {
                images.push({ key, value });
            }
            return images;
        }, []);

        if (!imagesToUpload.length) {
            // console.log(`[SYNC] - [message_id: ${message.message_id}] - No base64 images found in message`);
            return message;
        }

        console.log(`[SYNC] Processing ${imagesToUpload.length} images for message ${message.message_id || 'unknown'}`);

        syncProgress.value.imageProgress.total = imagesToUpload.length;
        syncProgress.value.imageProgress.current = 0;

        const processedMessage = {...message};

        for (const {key, value} of imagesToUpload) {
            try {
                const {data} = await Utils.uploadImageBase64(value);

                // Update the specific image URL in the message
                processedMessage.data[key] = data.path;

                // console.log(`[SYNC] - Image uploaded successfully`, { key, value, data });

                syncProgress.value.imageProgress.current++;
                hasInternet.value = true;
            } catch (err) {
                sendLog(`[SYNC] [ERROR] Image upload failed`, { err, messageId: message.message_id });
                if (hasInternet.value) {
                    await checkInternetConnection();
                }
                throw err;
            }
        }

        // Save the fully processed message once after all images are uploaded
        await indexedDBService.setItem(processedMessage.message_id, processedMessage);

        return processedMessage;
    }

    async function sync() {
        // console.log('sync()');
        // Guard clause to prevent concurrent syncs
        // if (isSyncing.value) {
        //     console.log('[SYNC] - Sync already in progress, skipping');
        //     return;
        // }

        isSyncing.value = true;

        try {
            const message = await getNextMessageToSync();
            if (!message) {
                // console.log('[SYNC] - No messages to sync');
                isSyncing.value = false;
                return;
            }

            // console.log(`[SYNC] - Start syncing message - ${message.message_id}`, { message });

            try {
                currentMessage.value = message;
                const processedMessage = await processMessageImages(message);
                const response = await ReportsApi.doUpdate(processedMessage.dataset_id, processedMessage);
                if (response?.success && response.data?.message_id === message.message_id) {
                    await indexedDBService.removeItem(message.message_id);
                    removeFromSyncQueue(message.message_id);
                    hasInternet.value = true;
                    // console.log(`[SYNC] - End syncing message, called sync again`, { message });
                    await sync();
                }
            } catch (err) {
                // sendLog(`[SYNC] [ERROR] Failed to sync message`, { err });
                if (hasInternet.value) {
                    await checkInternetConnection();
                }
                scheduleRetry();
            }
        } catch (err) {
            // sendLog(`[SYNC] [ERROR] message sync - Error`, { err });
            if (hasInternet.value) {
                await checkInternetConnection();
            }
            scheduleRetry();
        } finally {
            isSyncing.value = false;
        }
    }

    function scheduleRetry() {
        if (retryTimeout) clearTimeout(retryTimeout);
        retryTimeout = setTimeout(() => {
            retryTimeout = null;
            sync();
        }, RETRY_DELAY);
    }

    // Initialize visibility change listener
    if (typeof window !== 'undefined') {
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') init();
        });
        init();
    }

    return {
        // Core sync state
        isInitialized: readonly(isInitialized),
        isSyncing: readonly(isSyncing),
        currentMessage: readonly(currentMessage),
        syncError: readonly(syncError),
        syncProgress: readonly(syncProgress),
        hasInternet: readonly(hasInternet),

        // UI state
        showSync: readonly(showSync),
        hasImages: readonly(hasImages),
        currentImageCount: readonly(currentImageCount),
        totalImages: readonly(totalImages),
        syncedCount: readonly(syncedCount),

        // Methods
        addMessage,
        getNextMessageToSync,
        sync,
        checkInternetConnection,
        getSyncQueue
    };
}

// Create a global instance
const globalBackgroundSync = useBackgroundSync();
export default globalBackgroundSync;
