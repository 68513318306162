<template>
    <Impersonate />
    <Maintenance />
    <ForceRefresh />
    <VApp>
      <div class="r-the-main-layout">
        <AppHeader />
        <VMain v-if="isLoggedIn">
          <RouterView></RouterView>
        </VMain>
      </div>
      <AppSync v-if="isLoggedIn" />
    </VApp>
</template>

<script>
import { mapGetters } from "vuex";
import Impersonate from "@/components/Impersonate";
import AppHeader from "@/components/AppHeader.vue";
import AppSync from "./AppSync.vue";
import Maintenance from "@/components/Maintenance.vue";
import ForceRefresh from "@/components/ForceRefresh.vue";
export default {
  components: {
    ForceRefresh,
    Maintenance,
    AppHeader,
    Impersonate,
    AppSync,
  },
  data: () => ({
    drawer: false,
  }),

  computed: {
    ...mapGetters("auth", ["user", "company", "isLoggedIn"]),
  },

  async created() {
    if (this.$checkRole(["admin", "owner"])) {
      await this.$store.dispatch("users/fetchUsers");
    }
  },
};
</script>
