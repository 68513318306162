<template>
  <div v-if="isRendered" class="max-w-[700px] mx-auto px-4 py-[52px]">
    <RSimpleCard
      :disabled="isLoading"
      title="החלפת חברה"
      subtitle="מצאנו אותך במספר חברות, אנא בחר את החברה שאליה ברצונך להתחבר מהרשימה:"
    >
      <div class="changeCompanyList">
        <div
          v-for="(company, companyIndex) in companies"
          :key="companyIndex"
          class="changeCompanyList-item"
          :class="{
            'is-current': user.currentCompanyId === company.company_id,
          }"
        >
          <div class="changeCompanyList-item__details">
            #{{ company.company_id }} {{ company.name }}
          </div>
          <div class="changeCompanyList-item__action">
            <RChip
              v-if="user.currentCompanyId === company.company_id"
              color="purple"
            >
              חברה נוכחית
            </RChip>
            <RChip v-else-if="!company.isUserActive" color="red" dark>
              משתמש לא פעיל
            </RChip>
            <RChip v-else-if="!company.isCompanyActive" color="red" dark>
              חברה לא פעילה
            </RChip>
            <RButton
              v-else
              outlined
              sm
              @click="changeCompany(company.company_id)"
            >
              מעבר לחברה >
            </RButton>
          </div>
        </div>
      </div>
    </RSimpleCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AuthApi from "@/api/AuthApi";

export default {
  data() {
    return {
      selectedItem: 0,
      isRendered: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "companies"]),
  },
  async created() {
    this.isRendered = true;
  },
  methods: {
    async changeCompany(companyId) {
      let loader = this.$loading.show();

      try {
        await AuthApi.changeCompany(companyId);
        this.$toast.success("החברה הוחלפה בהצלחה, אנא המתינו ...");
        window.location.href = "/";
      } catch (e) {
        loader.hide();
        this.$toast.error("שגיאה בתהליך החלפת חברה");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.changeCompanyList {
  background: #fff;
  margin-inline: -16px;
  margin-top: 20px;

  &-item {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.is-current {
      background: #f9f9f9;
    }

    &:hover:not(.is-current) {
      background: #eee;
    }
  }
}
</style>
