<template>
  <div>
    <RFieldText class="mb-3"
      v-if="editContent"
      v-model="localItem.settings.headline"
      label="כותרת"
    />

    <RFieldAlign
      v-if="editContent"
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <RFieldAlign v-model="localItem.settings.imageAlign" label="יישור תמונה" />

    <AppFieldSlider
      v-model="localItem.settings.imageWidth"
      label="גודל תמונה"
      :min="1"
      :max="700"
    />

    <RFieldImage
      v-if="editContent"
      v-model="localItem.settings.imageUrl"
      label="תמונה"
    />
  </div>
</template>

<script>
import AppFieldSlider from "@/components/ReportTemplate/components/AppFieldSlider.vue";

export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    editContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
  components: {
    AppFieldSlider,
  },
};
</script>
