<template>
  <div v-if="isOnMaintenanceMode" class="maintenance">
    <div class="maintenance__content">
      <div class="maintenance__icon-wrapper">
        <div class="maintenance__icon">🛠️</div>
        <div class="maintenance__icon-shadow"></div>
      </div>

      <h2 class="maintenance__title">שלום חברים!</h2>

      <div class="maintenance__message">
        אנחנו עכשיו בתהליך שדרוג ותחזוקה כדי להבטיח שהאפליקציה שלנו תמשיך
        להיות הטובה ביותר עבורכם.
      </div>

      <div class="maintenance__time">
        <span>אנחנו מעריכים שעד השעה</span>
        <div class="maintenance__badge">
          <span class="maintenance__clock-icon">🕐</span>
          11:15
        </div>
        <span>הכל יחזור לשגרה חוויות חדשות ומשופרות.</span>
      </div>

      <div class="maintenance__footer">
        <p>תודה על הסבלנות והתמיכה,</p>
        <p class="maintenance__signature">צוות רפורטו 🙏</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOnMaintenanceMode: false,
    };
  },
};
</script>

<style lang="scss">
.maintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  padding: 20px;
  background: linear-gradient(135deg, rgba(13, 17, 23, 0.98), rgba(23, 27, 33, 0.98));
  color: #fff;
  z-index: 999999;
  backdrop-filter: blur(8px);

  &__content {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 24px;
    padding: 48px;
    max-width: 560px;
    text-align: center;
    box-shadow:
      0 20px 40px rgba(0, 0, 0, 0.2),
      0 0 100px rgba(33, 150, 243, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.12);
    position: relative;
  }

  &__icon-wrapper {
    position: relative;
    display: inline-block;
  }

  &__icon {
    font-size: 56px;
    margin-bottom: 24px;
    animation: float 3s ease-in-out infinite;
    filter: drop-shadow(0 4px 6px rgba(0,0,0,0.2));
  }

  &__icon-shadow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 8px;
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    animation: shadow 3s ease-in-out infinite;
  }

  &__title {
    font-size: 36px;
    margin-bottom: 28px;
    background: linear-gradient(90deg, #64B5F6, #2196F3, #1976D2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
    letter-spacing: -0.5px;
  }

  &__message {
    font-size: 18px;
    line-height: 1.7;
    margin-bottom: 32px;
    color: #e1e1e1;
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
  }

  &__badge {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background: rgba(33, 150, 243, 0.15);
    color: #2196F3;
    padding: 10px 20px;
    border-radius: 14px;
    font-weight: bold;
    margin: 12px 0;
    border: 1px solid rgba(33, 150, 243, 0.3);
    transition: all 0.3s ease;

    &:hover {
      background: rgba(33, 150, 243, 0.2);
      transform: translateY(-1px);
    }
  }

  &__clock-icon {
    font-size: 1.1em;
  }

  &__footer {
    margin-top: 36px;
    color: #b0b0b0;
    font-size: 15px;
  }

  &__signature {
    margin-top: 10px;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
  }
}

@keyframes float {
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-10px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
}

@keyframes shadow {
  0% { transform: translateX(-50%) scale(1); opacity: 0.4; }
  50% { transform: translateX(-50%) scale(0.8); opacity: 0.2; }
  100% { transform: translateX(-50%) scale(1); opacity: 0.4; }
}

@keyframes shine {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
