<template>
  <div class="max-w-[700px] mx-auto px-4 py-[52px]">
    <RSimpleCard title="הוספת משתמש חדש" :disabled="isLoading">
      <RFields>
        <RFieldText v-model="add_user.name" label="שם מלא" />

        <RFieldText v-model="add_user.email" label="אימייל" />

        <RFieldText v-model="add_user.password" type="password" label="סיסמא" />

        <RFieldText v-model="add_user.phone" label="טלפון" />

        <RFieldSelect
          v-model="add_user.role_id"
          :items="rolesChoices"
          label="הרשאות"
        />

        <RFieldCheckbox
          v-model="add_user.approve_newsletter"
          label="מאשר קבלת דיוור ישיר למייל עם חידושים, עדכונים וטיפים"
        />

        <RButton
          color="primary"
          block
          lg
          class="mt-2"
          :disabled="!isFormValid"
          :loading="isLoading"
          @click="addUser"
        >
          הוספת משתמש
        </RButton>
      </RFields>
    </RSimpleCard>
  </div>
</template>
<script>
import { roles } from "@/common/settings";
import { Utils } from "@/common/Utils";
import UsersApi from "@/api/UsersApi";

export default {
  data() {
    return {
      isLoading: false,
      valid: false,
      add_user: {
        role_id: 3,
        approve_newsletter: true,
      },
      roles,
    };
  },
  computed: {
    rolesChoices() {
      let roles = [];
      Object.keys(this.roles).forEach((key) => {
        // Exclude Owner role from Admins
        if (this.roles[key] === "Owner" && !this.$checkRole(["owner"])) {
          return;
        }
        roles.push({
          value: parseInt(key),
          text: this.$Utils.getRoleHebrewName(this.roles[key]),
        });
      });

      return roles;
    },
    isFormValid() {
      if (
        !this.add_user.email ||
        !this.add_user.email.length ||
        !Utils.isValidEmail(this.add_user.email)
      ) {
        return false;
      }

      if (!this.add_user.name) {
        return false;
      }

      if (!this.add_user.password) {
        return false;
      }

      if (!this.add_user.phone) {
        return false;
      }

      return true;
    },
  },
  methods: {
    isValidMobilePhone() {
        return /^05\d{8}$/.test(this.add_user.phone);
    },
    async addUser() {
      if (!this.isFormValid) return;

      if (!this.isValidMobilePhone(this.add_user.phone)) {
        this.$toast.error("חובה להזין טלפון תקין, ספרות בלבד");
        return;
      }

      this.isLoading = true;
      let add_user = { ...this.add_user };

      try {
        await UsersApi.createUser(add_user);

        this.$toast.success("המשתמש נוסף ושלחנו לו את פרטי ההתחברות בווטסאפ", {
          timeout: 12000,
        });

        await this.$router.push("/users");

        this.isLoading = false;
      } catch (error) {
        if (error.response.status === 422 && error.response.data) {
          console.log(error.response);
          if (error.response.data.errors && error.response.data.errors.email) {
            this.$toast.error("שגיאה: " + error.response.data.errors.email);
          }

          if (error.response.data.message === "user already inside company") {
            this.$toast.error("שגיאה: המשתמש כבר נמצא בחברה");
          } else if (error.response.data.message) {
            this.$toast.error("שגיאה: " + error.response.data.message);
          }
        } else {
          this.$toast.error("שגיאה: לא הצלחנו להוסיף את המשתמש");
        }

        this.isLoading = false;
      }
    },
  },
};
</script>
