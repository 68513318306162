<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">היי, טוב לראות אותך!</div>

      <RAlert
        v-if="verifyResendModal.isOpen"
        class="mt-3"
        title="כתובת המייל עדיין לא אומתה."
        type="warning"
      >
        <div>
          <b>בדקו את המייל שלכם</b>
          <br />
          שנשלח שוב את הלינק להפעלה?
        </div>

        <RButton sm class="mt-3" color="black" @click="verifyResend">
          כן שלחו לי מייל אימות שוב
        </RButton>
      </RAlert>

      <div class="r-auth-form">
        <form
          :class="{
            loading: isLoginFormLoading,
          }"
          @submit.prevent="validateBeforeSubmit"
        >
          <RFields>
            <RFieldText
              v-model="loginData.email"
              type="email"
              label="דואר אלקטרוני"
              autocomplete="off"
              placeholder="your@email.com"
            />

            <div class="r-auth-field-password">
              <RFieldText
                v-model="loginData.password"
                label="סיסמה"
                autocomplete="off"
                :type="passwordType"
                placeholder="הסיסמה שלך"
              />
              <div
                class="r-auth-field-password-visible"
                @click="showPassword = !showPassword"
              >
                <RIcon v-if="!showPassword">mdi-eye</RIcon>
                <RIcon v-else>mdi-eye-off</RIcon>
              </div>
            </div>
          </RFields>

          <div v-if="companiesToSelect.length" class="app-field mb-3 mt-3">
            <label class="app-field__label">
              מצאנו אותך בכמה חברות, לאיזו תרצו להתחבר?
            </label>
            <div class="companiesList mb-6 mt-2">
              <div
                v-for="company in companiesToSelect"
                :key="company.company_id"
                class="companiesList-item"
                :class="{
                  'companiesList-item--selected':
                    loginData.companyIdToLogin === company.company_id,
                }"
                @click="loginData.companyIdToLogin = company.company_id"
              >
                <div class="companiesList-item-name">
                  {{ getSelectCompanyText(company) }}
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex align-center justify-space-between flex-wrap">
            <router-link
              :to="{ name: 'forgot-password' }"
              style="
                font-size: 15px;
                color: var(--color-brand-700);
                font-weight: 500;
              "
            >
              <span>שכחת סיסמה?</span>
            </router-link>
          </div>

          <RButton
            lg
            block
            color="primary"
            class="mt-6"
            type="submit"
            :disabled="!loginData.email || !loginData.password"
            :loading="isLoginFormLoading"
          >
            כניסה
          </RButton>
        </form>
      </div>

      <RButton
        lg
        block
        color="primary"
        outlined
        class="mt-4 mb-10"
        icon-after="mdi-cellphone"
        :to="{ name: 'login-phone' }"
      >
        התחברות עם טלפון
      </RButton>

      <div class="r-auth-disclaimer">
        בהתחברות אתם מסכימים ל<a
          style="color: currentColor"
          href="https://reporto.co.il/reporto_terms_and_privacy.pdf"
          target="_blank"
          >תנאי השימוש ומדיניות הפרטיות</a
        >
        שלנו.
      </div>
    </div>
  </div>
</template>

<script>
import AuthApi from "@/api/AuthApi";
import * as types from "@/store/modules/auth/mutation-types";
import { mapGetters } from "vuex";
import { Utils } from "@/common/Utils";
import { trackEvent } from "@/services/TrackingService";
import { sendLog } from "@/services/LogService";

export default {
  data() {
    return {
      isLoginFormLoading: false,
      companiesToSelect: [],
      verifyResendModal: {
        isOpen: false,
        isLoading: false,
      },
      showPassword: false,
      loginData: {
        email: "",
        password: "",
        remember: false,
        device_name: this.$Utils.getDeviceId(),
        companyIdToLogin: null,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "company"]),
    passwordType() {
      return this.showPassword ? "text" : "password";
    },
  },
  methods: {
    async verifyResend() {
      let loader = this.$loading.show();

      try {
        await AuthApi.emailResendVerification(this.loginData.email);
        this.verifyResendModal.isOpen = false;
        this.$router.push({
          path: "/verify/sent",
          query: { email: this.loginData.email },
        });
        loader.hide();
      } catch (error) {
        this.$toast.success("שגיאה בשליחת מייל אימות");
        loader.hide();
      }
    },
    async validateBeforeSubmit() {
      this.verifyResendModal.isOpen = false;

      if (!Utils.isValidEmail(this.loginData.email)) {
        this.$toast.error("חובה להזין מייל תקין");
        return;
      }

      if (!this.loginData.password) {
        this.$toast.error("חובה להזין סיסמא");
        return;
      }

      this.isLoginFormLoading = true;

      try {
        const response = await AuthApi.login(this.loginData);

        this.$store.commit("auth/" + types.SET_TOKEN, response.data.token);
        await this.$store.dispatch("auth/fetchMe");

        sendLog("[UserAuth] : User Login Success:");
        trackEvent("User Login", { method: "email" });
        this.$posthog.capture("user_login:submit");

        this.isLoginFormLoading = false;

        await this.$router.push({ name: "home" });
      } catch (error) {
        console.log("error", error);
        this.$store.commit("auth/" + types.SET_IS_LOGGED_IN, false);
        this.$store.commit("auth/" + types.REMOVE_TOKEN);

        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "User or Company is not activated"
        ) {
          this.$toast.error("שגיאה: משתמש לא פעיל");

          trackEvent("User Login Error", {
            method: "email",
            reason: "User or Company is not activated",
          });

          this.companiesToSelect = error.response.data.data.companies;
          this.companiesToSelect.forEach((company) => {
            if (company.isCurrentCompany) {
              this.loginData.companyIdToLogin = company.company_id;
            }
          });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Your email address is not verified."
        ) {
          this.verifyResendModal.isOpen = true;

          trackEvent("User Login Error", {
            method: "email",
            reason: "Email not verified",
          });
        } else if (error.message === "Network Error") {
          this.$toast.error("שגיאה: אין חיבור אינטרנט תקין");

          trackEvent("User Login Error", {
            method: "email",
            reason: "Network Error",
          });

          this.isLoginFormLoading = false;
        } else {
          this.$toast.error("שגיאה: פרטים שגויים");

          trackEvent("User Login Error", {
            method: "email",
            reason: "Incorrect details",
          });
        }
        this.isLoginFormLoading = false;
      }
    },
    getSelectCompanyText(company) {
      if (!company.isUserActive) {
        return `${company.name} - כאן המשתמש לא פעיל`;
      }

      if (!company.isCompanyActive) {
        return `${company.name} - חברה לא פעילה`;
      }

      return `${company.name}`;
    },
  },
};
</script>

<style lang="scss">
.companiesList {
  font-size: 14px;
  &-item {
    padding: 8px 10px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: all 0.3s;
    background-color: #fff;

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:hover:not(.companiesList-item--selected) {
      background-color: #f5f5f5;
    }

    &:active:not(.companiesList-item--selected) {
      background-color: #e0e0e0;
    }

    &--selected {
      background-color: #e8d9fd;
      color: #5e21d2;
    }
  }
}
</style>
