<template>
	<div class="app-field app-field--switch">
	  <label v-if="label" class="app-field__label">
		{{ label }}
	  </label>
	  <v-btn-toggle v-model="inputText" mandatory>
		<v-tooltip v-for="(item, itemIndex) in items" :key="itemIndex" top>
		  <template #activator="{ props }">
			<v-btn v-bind="props" :value="item" outlined height="40">
			  <v-icon size="18">mdi-format-align-{{ item }}</v-icon>
			</v-btn>
		  </template>
		  <span>{{ translate(item) }}</span>
		</v-tooltip>
	  </v-btn-toggle>
	</div>
  </template>
  
  <script>
  export default {
	props: {
	  label: {
		type: [String, Number],
		default: "",
	  },
	  modelValue: {
		type: String,
		default: "",
	  },
	  items: {
		type: Array,
		default: () => ["right", "center", "left"],
	  },
	},
	data() {
	  return {
		inputText: this.modelValue,
	  };
	},
	watch: {
	  inputText(val) {
		this.$emit("update:modelValue", val);
	  },
	  modelValue(val) {
		this.inputText = val;
	  },
	},
	created() {
	  this.inputText = this.modelValue;
	},
	methods: {
	  translate(str) {
		if (str === "right") {
		  return "ימין";
		}
		if (str === "left") {
		  return "שמאל";
		}
		if (str === "center") {
		  return "אמצע";
		}
	  },
	},
  };
  </script>
  
  <style scoped lang="scss">
  .app-field--switch {
	display: flex;
	align-items: center;
	justify-content: space-between;
  
	&__label {
	  margin-bottom: 0;
	  margin-left: 5px;
	}
  
	.v-input--selection-controls__input {
	  margin-left: 0;
	}
  
	.v-input--switch--inset .v-input--selection-controls__ripple {
	  transform: translate(-5px, 0) !important;
	}
  
	.v-input--selection-controls__input .primary--text {
	  color: var(--color-green) !important;
	}
  }
  </style>
  