<template>
  <div v-if="isLoaded" class="r-page-report">
    <ReportHeader />
    <template v-if="isReportWithDefects">
      <div class="flex bg-white border-b">
        <button
          v-if="isReportWithDefects"
          @click="currentNavItem = 'defects'"
          :class="[
            'flex items-center justify-center gap-1 !px-2 py-3 sm:gap-2 sm:!px-6 relative sm:flex-initial flex-1',
            currentNavItem === 'defects'
              ? 'text-violet-600 bg-violet-50/50 font-medium border-b-2 border-violet-600'
              : 'text-gray-600 hover:text-violet-600'
          ]"
        >
          <v-icon class="text-lg">mdi-text-box-outline</v-icon>
          <span class="text-base">רשימת ממצאים</span>
          <span
            v-if="currentNavItem === 'defects'"
            class="absolute bottom-0 left-0 right-0 h-0.5 bg-violet-600"
          />
        </button>
        <button
          v-if="isReportWithLists"
          @click="currentNavItem = 'lists'"
          :class="[
            'flex items-center justify-center gap-1 !px-2 py-3 sm:gap-2 sm:!px-6 relative sm:flex-initial flex-1',
            currentNavItem === 'lists'
              ? 'text-violet-600 bg-violet-50/50 font-medium border-b-2 border-violet-600'
              : 'text-gray-600 hover:text-violet-600'
          ]"
        >
          <v-icon class="text-lg">mdi-format-list-checks</v-icon>
          <span class="text-base">צ׳קליסטים וטבלאות</span>
          <span
            v-if="currentNavItem === 'lists'"
            class="absolute bottom-0 left-0 right-0 h-0.5 bg-violet-600"
          />
        </button>
        <button
          @click="currentNavItem = 'texts'"
          :class="[
            'flex items-center justify-center gap-1 !px-2 py-3 sm:gap-2 sm:!px-6 relative sm:flex-initial flex-1',
            currentNavItem === 'texts'
              ? 'text-violet-600 bg-violet-50/50 font-medium border-b-2 border-violet-600'
              : 'text-gray-600 hover:text-violet-600'
          ]"
        >
          <v-icon class="text-lg">mdi-alert-circle-outline</v-icon>
          <span class="text-base">פרטים נוספים</span>
          <span
            v-if="currentNavItem === 'texts'"
            class="absolute bottom-0 left-0 right-0 h-0.5 bg-violet-600"
          />
        </button>
      </div>
      <div class="r-page-report-body">
        <div v-if="currentNavItem === 'defects'">
          <ReportDefects v-if="isReportWithDefects" />
        </div>
        <div v-if="currentNavItem === 'lists'">
          <ReportListsItems />
        </div>
        <div v-if="currentNavItem === 'texts'">
          <ReportTextsItems />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="r-page-report-body">
        <ReportItems />
      </div>
    </template>
  </div>
  <PageLoader v-else />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportHeader from "./components/ReportHeader";
import ReportItems from "./components/ReportItems";
import ReportDefects from "./components/ReportDefects";
import ReportListsItems from "./components/ReportListsItems";
import ReportTextsItems from "./components/ReportTextsItems";
import { Utils } from "@/common/Utils";
import PageLoader from "@/components/PageLoader.vue";
import { useBackgroundSync } from '@/composables/useBackgroundSync';

const { isSyncing, hasInternet } = useBackgroundSync();


export default {
  components: {
    ReportItems,
    ReportTextsItems,
    ReportListsItems,
    PageLoader,
    ReportDefects,
    ReportHeader,
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    if (to.params.id !== undefined) {
      this.reportId = to.params.id;
      await this.initialize();
    }
    return next();
  },

  data() {
    return {
      currentNavItem: "texts",
      isLoaded: false,
      reportId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    ...mapGetters("stocks", ["stocks"]),
    isReportWithLists() {
      let withLists = false;
      if (this.report.data.items) {
        for (const [, item] of Object.entries(this.report.data.items)) {
          if (item.type === "repeater" || item.type === "table") {
            withLists = true;
          }
        }
      }
      return withLists;
    },
    isReportWithDefects() {
      let withDefects = false;
      if (this.report.data.items) {
        for (const [, item] of Object.entries(this.report.data.items)) {
          if (
            item.type === "defectsTable" ||
            item.type === "miniDefectsTable"
          ) {
            withDefects = true;
          }
        }
      }
      return withDefects;
    },
    stock() {
      let stockFound = false;

      if (!this.report.stock_id) {
        return;
      }
      if (!this.stocks) {
        return;
      }

      this.stocks.forEach((stock) => {
        if (stock.id === this.report.stock_id) {
          stockFound = stock;
        }
      });

      return stockFound;
    },
  },
  async created() {
    await this.initialize();
    // this.preloadAllImages();
  },

  beforeRouteLeave(to, from, next) {
    let warningMsg;
    if (!hasInternet.value) {
      this.$posthog.capture("log:report:before_leave_no_internet_warning", {
        report_id: this.reportId,
      });
      warningMsg =
        "רגע! שמנו לב שאתם במצב ללא קליטת אינטרנט. אם תעזבו את העמוד הזה תצטרכו חיבור אינטרנט כדי לחזור. בטוחים שתרצו לעזוב את העמוד?";
    } else if (isSyncing.value) {
      this.$posthog.capture("log:report:before_leave_syncing_warning", {
          report_id: this.reportId,
      });
      warningMsg =
        "רגע! שמנו לב שלא הסתנכרנו כל השינויים עדיין. תרצו להמתין לסנכרון השינויים באופן מלא?";
    }

    if (warningMsg) {
      const answer = window.confirm(warningMsg);
      next(!!answer);
    } else {
      next();
    }
  },

  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    async initialize() {
      await this.$store.dispatch("report/getReport", {
        reportId: this.reportId,
      });

      if (this.isReportWithLists) {
        this.currentNavItem = "lists";
      }

      if (this.isReportWithDefects) {
        await this.$store.dispatch("stocks/fetchStocks");
        this.currentNavItem = "defects";
      }

      this.isLoaded = true;

      if (this.$checkRole(["admin", "owner"])) {
        await this.$store.dispatch("users/fetchUsers");
      }
      await this.$store.dispatch("preferences/fetchPreferences");
      await this.$store.dispatch("projects/fetchProjects");
    },
    // preloadAllImages() {
    //   let objFlatten = Utils.flattenObject({
    //     // ...this.report,
    //     ...this.stock,
    //   });

    //   for (const [, value] of Object.entries(objFlatten)) {
    //     if (typeof value === "string" && value.includes("images/")) {
    //       let img = new Image();
    //       img.src = this.$Reporto.globals.MEDIA_URL + value;
    //     }
    //   }
    // },
  },
};
</script>
