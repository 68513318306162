<template>
  <div>
    <RFieldText
      v-model="localItem.settings.headline"
      label="כותרת"
      class="mb-3"
    />

    <RFieldAlign
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
  },
};
</script>
