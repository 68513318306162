<template>
  <div class="max-w-[700px] mx-auto px-4 py-[52px]" v-if="isLoaded">
    <RSimpleCard
      title="יצירת מאגר חדש"
      subtitle="בחרו איזה איזה מאגר תרצו להוסיף"
    >
      <div class="stocksList mb-6 mt-4">
        <div
          v-for="stock in stocksLibrary"
          :key="stock.id"
          class="stocksList-item"
          :class="{ 'stocksList-item--selected': stockIdToAdd === stock.id }"
          @click="stockIdToAdd = stock.id"
        >
          <div class="stocksList-item-name">{{ stock.name }}</div>
        </div>
      </div>

      <RButton
        color="primary"
        :loading="isLoading"
        :disabled="!stockIdToAdd"
        block
        lg
        @click="addStock"
      >
        הוספת מאגר חדש
      </RButton>
    </RSimpleCard>
  </div>
  <page-loader v-else />
</template>
<script>
import StocksApi from "@/api/StocksApi";
import { mapGetters } from "vuex";
import PageLoader from "@/components/PageLoader.vue";

export default {
  components: { PageLoader },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      stockIdToAdd: null,
    };
  },
  computed: {
    ...mapGetters("stocks", ["stocksLibrary"]),
  },
  async created() {
    await this.$store.dispatch("stocks/fetchStocksLibrary");
    this.isLoaded = true;
  },
  methods: {
    async addStock() {
      this.isLoading = true;

      try {
        await StocksApi.createStock(this.stockIdToAdd);
        this.$toast.success("המאגר נוסף בהצלחה");
        this.$router.push("/stocks");
        this.isLoading = false;
      } catch (error) {
        this.$toast.error("שגיאה בהוספת המאגר");

        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.stocksList {
  &-item {
    padding: 10px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: all 0.3s;

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:hover:not(.stocksList-item--selected) {
      background-color: #f5f5f5;
    }

    &:active:not(.stocksList-item--selected) {
      background-color: #e0e0e0;
    }

    &--selected {
      background-color: #e8d9fd;
      color: #5e21d2;
    }
  }
}
</style>
