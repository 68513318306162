<template>
  <div class="r-field r-field--textChoices">
    <label v-if="label" class="r-field-label"> {{ label }} </label>
    <div class="r-field-input mb-3">
      <input v-model="inputText" v-bind="$attrs"/>
      <div v-if="choices" class="mt-3">
        <RButton
            v-for="choice in choicesFormatted"
            :key="choice.id"
            :color="choice.color"
            :style="getBtnStyle(choice)"
            :outlined="choice.value !== inputText"
            class="ml-1 mb-2"
            sm
            @click="choiceClicked(choice)"
        >
          {{ choice.label }}
        </RButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, Number],
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    choices: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      inputText: this.modelValue,
    };
  },
  computed: {
    choicesFormatted() {
      let choicesFormatted = [];

      for (const [, choice] of Object.entries(this.choices)) {
        choicesFormatted.push({
          value: choice.value ? choice.value : choice.label,
          label: choice.label,
          color:
              choice.color &&
              choice.color !== "#ffffff" &&
              choice.color !== "#fff"
                  ? choice.color
                  : "black",
        });
      }
      return choicesFormatted;
    },
  },

  watch: {
    inputText(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.inputText = val;
    },
  },

  methods: {
    choiceClicked(choice) {
      if (!this.multiple) {
        this.inputText = choice.value;
      } else {
        // add comma separated values if multiple, and don't remove values if already present
        if (this.inputText) {
          this.inputText += this.inputText ? `, ${choice.value}` : choice.value;
        } else {
          this.inputText = choice.value;
        }
      }
    },
    updateParentValue(val) {
      this.inputText = val;
    },
    getBtnStyle(choice) {
      if (choice.value === this.inputText) {
        return `background-color: ${choice.color}; color: white;`;
      } else {
        return `color: ${choice.color} !important; border-color: ${choice.color} !important;`;
      }
    },
  },
};
</script>
