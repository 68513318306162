<template>
  <div v-if="shouldShowSync && (isSyncing || !hasInternet)"
       class="sync-card"
       :class="{
         'minimized': isMinimized,
         'error': syncError,
         'offline': !hasInternet
       }">
    <div class="card-header">
      <div class="header-left">
        <div class="sync-icon">
          <div class="sync-spinner"></div>
        </div>
        <h3 class="title">{{ headerTitle }}</h3>
      </div>
      <RButtonIcon
          color="white"
          @click="toggleMinimize"
          :title="isMinimized ? 'הרחב' : 'כווץ'"
          :icon="isMinimized ? 'mdi-arrow-expand' : 'mdi-arrow-collapse'"
          class="mr-2"
      />
    </div>

    <div v-if="!isMinimized" class="card-body">
      <div class="sync-status">
        <div class="sync-info">
          <div class="sync-counter">
            <span class="count">{{ syncedCount }}/{{ totalCount }}</span>
            <span class="label">פריטים</span>
            <span v-if="hasImages" class="image-counter">
              ({{ currentImageCount }}/{{ totalImages }} תמונות)
            </span>
          </div>
          <span class="sync-text">{{ getSyncStatusText() }}</span>
        </div>
      </div>

      <div class="progress-section">
        <div class="progress-bar">
          <div class="progress-fill" :style="{ width: `${localProgressPercentage}%` }">
            <div class="progress-pulse"></div>
          </div>
        </div>
        <span class="progress-percentage">{{ localProgressPercentage }}%</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { useBackgroundSync } from '@/composables/useBackgroundSync';
import { sendLog } from '@/services/LogService';
import indexedDBService from "@/services/IndexedDBService";

const {
  isSyncing,
  currentMessage,
  syncError,
  currentImageCount,
  totalImages,
  hasImages,
  hasInternet,
  sync,
  getSyncQueue
} = useBackgroundSync();

const isMinimized = ref(false);
const shouldShowSync = ref(false);
const isInitialSync = ref(true);
const syncQueue = ref([]);

const totalCount = computed(() => {
  return syncQueue.value.length;
});

const syncedCount = ref(0);

const localProgressPercentage = computed(() => {
  if (!totalCount.value) return 0;
  return Math.round((syncedCount.value / totalCount.value) * 100);
});

const toggleMinimize = () => {
  isMinimized.value = !isMinimized.value;
};

const headerTitle = computed(() => {
  if (!hasInternet.value) return 'אין חיבור לאינטרנט';
  return 'מסנכרן שינויים';
});

const getSyncStatusText = () => {
  if (!hasInternet.value) {
    return 'ממתין לחיבור אינטרנט...';
  }
  if (syncError.value) {
    return `שגיאת סנכרון: ${syncError.value}. מנסה שוב...`;
  }
  if (currentMessage.value?.data) {
    const location = currentMessage.value.report_address || 'מיקום לא ידוע';
    const reportNumber = currentMessage.value.report_number ?
      `דוח מס׳: ${currentMessage.value.report_number}` : '';
    return `${reportNumber}`;
  }
  return 'מתכונן לסנכרון...';
};

const checkPendingSync = async () => {
    try {
        syncQueue.value = getSyncQueue();
        if (syncQueue.value.length) {
            console.log(`[SYNC ON LOAD]: Found ${syncQueue.value.length} pending sync items`);
            shouldShowSync.value = true;
            await sync();
        }
    } catch (err) {
        sendLog('[SYNC ON LOAD] [ERROR]: Error checking pending sync', { err });
    }
};

// Add ref to store interval ID
const syncCountIntervalId = ref(null);

const startSyncCountInterval = () => {
    syncCountIntervalId.value = setInterval(() => {
        const currentQueue = getSyncQueue();
        syncedCount.value = Math.max(0, syncQueue.value.length - currentQueue.length);
    }, 1000);

    onUnmounted(() => {
        if (syncCountIntervalId.value) {
            clearInterval(syncCountIntervalId.value);
        }
    });
};

watch(isSyncing, async (newValue) => {
    if (!newValue && isInitialSync.value && shouldShowSync.value) {
        const queue = getSyncQueue();
        if (queue.length === 0) {
            // Clear the interval when sync is complete
            if (syncCountIntervalId.value) {
                clearInterval(syncCountIntervalId.value);
            }
            sendLog('[SYNC ON LOAD]: Complete - reloading page');
            isInitialSync.value = false;
            setTimeout(() => window.location.reload(), 500);
        }
    }
});

const compareLocalStorageToIndexedDB = async () => {
    try {
        const syncQueue = getSyncQueue();
        // put on const localStorageKeys from syncQueue only the messageId from the objects
        const localStorageKeys = syncQueue.map(item => item.messageId);
        const indexedDBKeys = await indexedDBService.getAllKeys();

        const missingKeys = localStorageKeys.filter(key => !indexedDBKeys.includes(key));
        if (missingKeys.length) {
            sendLog('[SYNC ON LOAD] [ERROR]: Found missing keys in localStorage but not on IndexedDB', { indexedDBKeys, missingKeys });
            shouldShowSync.value = true;
        }
    } catch (err) {
        sendLog('[SYNC ON LOAD] [ERROR]: Error comparing localStorage to IndexedDB', { err });
    }
};

onMounted(() => {
    checkPendingSync();
    compareLocalStorageToIndexedDB();
    startSyncCountInterval();
});
</script>

<style scoped>
.sync-card {
  position: fixed;
  bottom: 24px;
  left: 24px;
  background: #1e293b;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 380px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  direction: rtl;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index:100;
  @media (max-width: 430px) {
    width: calc(100% - 24px - 24px);
  }
}

.sync-card.minimized {
  width: auto;
  max-width: 220px;
}

.card-header {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 48px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sync-icon {
  width: 20px;
  height: 20px;
  padding: 3px;
}

.sync-spinner {
  width: 100%;
  height: 100%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top: 2px solid #60a5fa;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}

.card-body {
  padding: 16px;
}

.sync-status {
  margin-bottom: 16px;
}

.sync-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.sync-counter {
  display: flex;
  align-items: center;
  gap: 6px;
}

.count {
  font-size: 16px;
  font-weight: 600;
  color: #60a5fa;
}

.label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.sync-text {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.progress-section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.progress-bar {
  flex: 1;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #60a5fa;
  border-radius: 8px;
  transition: width 0.3s ease;
  position: relative;
}

.progress-pulse {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  animation: pulse 2s ease-in-out infinite;
}

.progress-percentage {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  min-width: 45px;
  text-align: left;
}

/* Error States */
.sync-card.error .sync-spinner {
  border-top-color: #f87171;
}

.sync-card.error .count {
  color: #f87171;
}

.sync-card.error .progress-fill {
  background: #f87171;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.image-counter {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 8px;
}

/* Add offline state styles */
.sync-card.offline {
  background: #991b1b;
}

.sync-card.offline .sync-spinner {
  border-top-color: #fecaca;
}

.sync-card.offline .count {
  color: #fecaca;
}

.sync-card.offline .progress-fill {
  background: #fecaca;
}

.sync-card.offline .sync-text,
.sync-card.offline .title,
.sync-card.offline .label,
.sync-card.offline .progress-percentage {
  color: #fecaca;
}

.sync-card.offline .progress-bar {
  background: rgba(254, 202, 202, 0.2);
}
</style>
