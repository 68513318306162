<template>
  <div v-if="isLoaded" class="r-page-edit">
    <div class="r-page-edit-header">
      <div class="r-page-edit-header-content">
        <div class="r-page-edit-header-title">⚙️ הגדרות חברה</div>
      </div>
    </div>

    <div class="r-page-edit-body">
      <div class="r-page-edit-body-container" style="max-width: 700px">
        <div class="r-page-edit-body-content">
          <RAlert
            v-if="!companySettings.subscription.autoRenew"
            class="mb-4"
            type="warning"
          >
            <div style="font-size: 16px; font-weight: 700">
              המנוי שלכם בתהליך ביטול️
            </div>
            <div>
              בהתאם לבקשתכם, המנוי יבוטל בעוד

              <b
                >{{
                  $Utils.daysLeft(companySettings.subscription.endsAt)
                }}
                ימים</b
              >
              בתאריך

              <b>{{
                $Utils.convertDatetimeToDisplayDateFormat(
                  companySettings.subscription.endsAt
                )
              }}</b
              >.
              <br />
              לכם ולמשתמשים שלכם לא תהיה יותר גישה למערכת.
            </div>

            <div class="mt-4">
              <b>רוצים להישאר?</b>
              <br />
              במידה ואתם רוצים להישאר איתנו לחצו על הכפתור למטה.
            </div>

            <RButton color="blue" class="mt-3" sm @click="activateSubscription"
              >השאירו לי את המנוי פעיל</RButton
            >
          </RAlert>
          <RSimpleCard
            v-if="
              company.isInGracePeriod && company.paymentMethod === 'creditCard'
            "
            title="יש לעדכן את פרטי התשלום 🚨"
            class="mb-4"
          >
            <div>
              נתקלנו בבעיה שקשורה לפרטי החיוב שלך.
              <br />
              החשבון שלך יושעה בעוד
              <b
                >{{
                  company.daysLeftInGracePeriod
                    ? company.daysLeftInGracePeriod
                    : "-"
                }}
                ימים
              </b>
            </div>

            <RButton
              v-if="companySettings.billingCardLast4Digits"
              class="mt-3"
              sm
              @click="tryToFixPayment"
              >ניסיון חוזר לתשלום</RButton
            >
          </RSimpleCard>
          <RSimpleCard
            v-if="company.isTrial"
            class="mb-4"
            title="אתם כרגע בתקופת ניסיון 💡"
          >
            נותרו עוד
            <b
              >{{ $Utils.daysLeft(companySettings.subscription.endsAt) }}
              ימים
            </b>
            עד לסיום תקופת הניסיון החינמית.

            <div class="mt-2">
              <div style="font-weight: 500">
                איך ממשיכים גם אחרי שתיגמר תקופת הניסיון?
              </div>
              פשוט השאירו את פרטי האשראי שלכם כאן בדף למטה ואנחנו כבר נדאג לשאר.
            </div>

            <RButton
              v-if="companySettings.billingPaymentMethod === 'creditCard'"
              sm
              class="mt-3"
              color="primary"
              @click="openUpdateCreditCardModal"
              >הזנת פרטי אשראי</RButton
            >
          </RSimpleCard>
          <RSimpleCard title="פרטי החברה" class="mb-4">
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4 max-w-[1100px]">
              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">שם החברה</div>
                <div class="text-gray-700 text-sm">
                  {{ companySettings.name ? companySettings.name : "" }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">מייל חברה</div>
                <div class="text-gray-700 text-sm">
                  {{ companySettings.email ? companySettings.email : "" }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">שם איש קשר</div>
                <div class="text-gray-700 text-sm">
                  {{
                    companySettings.billingContactName
                      ? companySettings.billingContactName
                      : ""
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">טלפון איש קשר</div>
                <div class="text-gray-700 text-sm">
                  {{
                    companySettings.billingContactPhone
                      ? companySettings.billingContactPhone
                      : ""
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">שם על החשבונית</div>
                <div class="text-gray-700 text-sm">
                  {{
                    companySettings.billingTaxName
                      ? companySettings.billingTaxName
                      : ""
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">מספר עוסק לחשבונית</div>
                <div class="text-gray-700 text-sm">
                  {{
                    companySettings.billingTaxId
                      ? companySettings.billingTaxId
                      : ""
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">
                  מייל לקבלת החשבונית
                </div>
                <div class="text-gray-700 text-sm">
                  {{
                    companySettings.billingTaxEmail
                      ? companySettings.billingTaxEmail
                      : ""
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">לוגו</div>
                <div class="flex justify-center">
                  <img
                    v-if="companySettings.logo"
                    style="max-width: 80%; height: auto; max-height: 60px"
                    :src="$Utils.getImageUrl(companySettings.logo)"
                    alt="logo"
                  />
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">דחיסת דוח PDF</div>
                <div class="flex">
                  <RChip
                    v-if="companySettings.dataDisablePdfCompression"
                    color="red"
                    >דחיסת PDF לא פעילה</RChip
                  >
                  <RChip v-else color="green"> דחיסת PDF פעילה</RChip>
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">נשלח מאת - מייל</div>
                <div class="text-gray-700 text-sm">
                  {{
                    companySettings.dataFromEmail
                      ? companySettings.dataFromEmail
                      : ""
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">נשלח מאת - שם</div>
                <div class="text-gray-700 text-sm">
                  {{
                    companySettings.dataFromName
                      ? companySettings.dataFromName
                      : ""
                  }}
                </div>
              </div>
            </div>
            <RButton
              class="mt-6"
              color="purple"
              @click="openUpdateCompanySettingsModal"
              >עדכון פרטי החברה</RButton
            >
          </RSimpleCard>

          <RSimpleCard v-if="!company.isTrial" class="mb-4" title="המנוי שלי">
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4 max-w-[1100px]">
              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">מתאריך</div>
                <div class="text-gray-700 text-sm">
                  {{
                    $Utils.convertDatetimeToDisplayDateFormat(
                      companySettings.subscription.startsAt
                    )
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">עד תאריך</div>
                <div class="text-gray-700 text-sm">
                  {{
                    $Utils.convertDatetimeToDisplayDateFormat(
                      companySettings.subscription.endsAt
                    )
                  }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">מחיר</div>
                <div class="text-gray-700 text-sm">
                  {{ companySettings.subscription.planPrice }} ₪
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">מחזור חיוב</div>
                <div class="text-gray-700 text-sm">
                  {{ billingPlanPeriodText }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">דוחות</div>
                <div class="text-gray-700 text-sm">
                  {{ companySettings.subscription.planReports }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">מחיר לכל דוח נוסף</div>
                <div class="text-gray-700 text-sm">
                  {{ companySettings.subscription.planPricePerExtraReport }} ₪
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">שיטת תשלום</div>
                <div class="text-gray-700 text-sm">
                  {{ $Utils.translate(companySettings.billingPaymentMethod) }}
                </div>
              </div>

              <div class="flex flex-col">
                <div class="text-gray-500 text-sm mb-1">פרטי אשראי</div>
                <div class="text-gray-700 text-sm">
                  <span v-if="billingCardLast4DigitsWithFakeNumbers">
                    {{ billingCardLast4DigitsWithFakeNumbers }}
                  </span>
                  <span v-else class="text-red-500">חסרים פרטי אשראי</span>
                </div>
              </div>
            </div>

            <RButton
              v-if="companySettings.billingPaymentMethod === 'creditCard'"
              class="mt-3"
              color="purple"
              @click="openUpdateCreditCardModal"
              >עדכון פרטי אשראי</RButton
            >
          </RSimpleCard>

          <!--
          <RSimpleCard v-if="!company.isTrial" class="mb-4" title="התשלום הבא">
            <RSimpleCardDetails>
              <RSimpleCardDetail label="תאריך התשלום הבא">
                {{ nextPaymentDate }}
              </RSimpleCardDetail>
              <RSimpleCardDetail label="סכום החיוב הבא">
                {{ companyNextPayment.amount }}
                ₪
              </RSimpleCardDetail>
              <RSimpleCardDetail label="סה״כ דוחות">
                {{ companyNextPayment.reports }}
              </RSimpleCardDetail>
              <RSimpleCardDetail label="תקופה - מתאריך">
                {{
                  $Utils.convertDatetimeToDisplayDateFormat(
                    companyNextPayment.periodStartDate
                  )
                }}
              </RSimpleCardDetail>
              <RSimpleCardDetail label="תקופה - עד תאריך">
                {{
                  $Utils.convertDatetimeToDisplayDateFormat(
                    companyNextPayment.periodEndDate
                  )
                }}
              </RSimpleCardDetail>
            </RSimpleCardDetails>
          </RSimpleCard>
          -->

          <div
            v-if="payments.length"
            class="cardTable mb-4"
            style="max-width: 800px"
          >
            <div class="cardTable__header">
              <div class="cardTable__header__title">
                היסטוריית תשלומים
                <small>({{ payments.length }})</small>
              </div>
            </div>
            <div class="cardTable__table">
              <div class="cardTable__tableResponsive">
                <table>
                  <thead>
                    <tr>
                      <th>סטטוס</th>
                      <th>תשלום לתקופה</th>
                      <th>מחיר כולל מע״מ</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="payment in payments" :key="payment.id" class="">
                      <td>
                        <RChip v-if="payment.status === 'closed'" color="red">
                          סגור
                        </RChip>
                        <RChip v-else color="green"> פתוח </RChip>
                      </td>
                      <td>
                        {{
                          $Utils.convertDatetimeToDisplayDateFormat(
                            payment.fromDate
                          )
                        }}
                        -
                        {{
                          $Utils.convertDatetimeToDisplayDateFormat(
                            payment.toDate
                          )
                        }}
                      </td>
                      <td>
                        <span>
                          {{ payment.amountWithTaxes }}
                          ₪</span
                        >
                        <span
                          v-if="payment.type === 'subscription_period_extra'"
                        >
                          (תוספת דוחות)
                        </span>
                      </td>
                      <td>
                        <span
                          v-if="payment.hasInvoice"
                          class="u-cursor-pointer text-decoration-underline"
                          style="font-size: 13px; color: var(--color-brand-600)"
                          @click="downloadInvoice(payment.id)"
                        >
                          הורדת חשבונית
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <RSimpleCard
            title="צור קשר עם התמיכה"
            subtitle="צוות התמיכה שלנו כאן בשבילך מתי שתצטרך עם כל שאלה שיש לך!"
          >
            <div class="support-buttons mt-3">
              <RButton
                icon-before="mdi-whatsapp"
                color="purple"
                @click="openSupport"
                >וואטסאפ איתנו</RButton
              >
              <RButton
                v-if="
                  companySettings.subscription.autoRenew && !company.isTrial
                "
                outlined
                color="red"
                icon-before="mdi-close"
                @click="cancelModal.isOpen = true"
              >
                ביטול מנוי
              </RButton>
            </div>
          </RSimpleCard>
        </div>
      </div>
    </div>

    <RModal v-model="updateCompanySettingsModal.isOpen" title="עדכון פרטי חברה">
      <form
        ref="updateCompanySettingsForm"
        @submit.prevent="submitCompanySettingsModal"
      >
        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.name"
          label="שם חברה*"
          required
          disabled
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.email"
          label="מייל חברה*"
          required
          disabled
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.billing.contactName"
          label="שם איש קשר*"
          required
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.billing.contactPhone"
          label="טלפון איש קשר*"
          required
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.billing.taxName"
          label="שם על החשבונית*"
          required
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.billing.taxId"
          label="מספר עוסק לחשבונית"
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.billing.taxEmail"
          label="מייל לקבלת החשבונית*"
          type="email"
          required
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.data.fromEmail"
          label="נשלח מאת - מייל"
          type="email"
        />

        <RFieldText
          class="mb-3"
          v-model="updateCompanySettingsModal.data.fromName"
          label="נשלח מאת - שם"
          type="text"
        />

        <RFieldImage
          v-model="updateCompanySettingsModal.logo"
          label="לוגו חברה"
        />

        <RFieldSwitch
          v-model="updateCompanySettingsModal.data.disablePdfCompression"
          label="כיבוי דחיסת דוחות PDF"
          :tooltip="'כששדה זה מסומן, הדוחות שלכם לא ידחסו ויהיו קלים יותר <br> סמנו את זה רק אם יש לכם בעיה של איכות תמונות בדוח.'"
        />

        <RButton
          color="primary"
          block
          lg
          :loading="updateCompanySettingsModal.isLoading"
          type="submit"
        >
          שמירת פרטי החברה
        </RButton>
      </form>
    </RModal>

    <RModal
      v-model="updateCreditCardModal.isOpen"
      persistent
      title="עדכון אמצעי תשלום"
    >
      <div v-if="updateCreditCardModal.creditCardIframeUrl">
        <div
          class="mb-2"
          style="
            color: #000;
            background: #f0f0f0;
            padding: 5px 10px;
            border-radius: 4px;
          "
        >
          <div style="font-weight: 500">לא ייגבה חיוב בפועל</div>
          <div>סכום זה משמש לבדיקת תקינות הכרטיס מול חברת האשראי.</div>
        </div>

        <iframe
          title="Credit card Iframe"
          width="100%"
          height="530"
          name="update-credit-card"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          scrolling="yes"
          :src="updateCreditCardModal.creditCardIframeUrl"
        ></iframe>

        <div class="secure">
          <img
            class="secure-cards"
            src="@/assets/images/cards.png"
            alt="cards"
          />
          <div style="text-align: center; font-size: 14px">
            דף זה מאובטח בהצפנת SSL 2048bit.
            <br />
            המידע אודות הפעולה מוצפן בהתאם להנחיות תקן PCI הבינלאומי.
          </div>
        </div>
      </div>
      <div v-else class="d-flex align-center justify-center pa-2">
        <RIconLoading />
      </div>
    </RModal>

    <RModal
      v-model="cancelModal.isOpen"
      :persistent="false"
      title="מצטערים שאתם עוזבים"
    >
      <div style="color: #000">
        שימו לב שעל ידי ביטול המנוי שלכם למשתמשים שלכם
        <span style="font-weight: 600">
          לא תהיה יותר גישה למערכת החל מה
          {{
            $Utils.convertDatetimeToDisplayDateFormat(
              companySettings.subscription.endsAt
            )
          }}
          .
        </span>
      </div>

      <div
        style="font-weight: 600; color: #000; font-size: 16px"
        class="mt-7 mb-3"
      >
        לפני שאתם עוזבים, נשמח לדעת מה הסיבה כדי שנוכל להשתפר.
      </div>

      <RFieldRadio
        v-model="cancelModal.reasonSelected"
        class="mb-9"
        :items="[
          { text: 'סגירת/צמצום העסק שלי', value: 'סגירת/צמצום העסק שלי' },
          {
            text: 'המערכת לא מתאימה לצורכי העסק שלי',
            value: 'המערכת לא מתאימה לצורכי העסק שלי',
          },
          {
            text: 'לא הצלחתי להטמיע את המערכת',
            value: 'לא הצלחתי להטמיע את המערכת',
          },
          { text: 'המחיר יקר לי', value: 'המחיר יקר לי' },
          {
            text: 'אין לי כרגע עבודה/צורך במערכת',
            value: 'אין לי כרגע עבודה/צורך במערכת',
          },
          { text: 'אין לי צורך במערכת', value: 'אין לי צורך במערכת' },
          { text: 'עברתי למערכת אחרת', value: 'עברתי למערכת אחרת' },
          { text: 'לא מרוצה מהשירות', value: 'לא מרוצה מהשירות' },
          { text: 'חסר לי פיצ׳ר חשוב', value: 'חסר לי פיצ׳ר חשוב' },
          { text: 'אחר (אנא הסבירו מטה)', value: 'אחר (אנא הסבירו מטה)' },
        ]"
      />
      <RFieldTextarea
        v-model="cancelModal.reasonText"
        rows="5"
        label="משהו נוסף שתרצו לחלוק?"
      />

      <RButton
        :disabled="!cancelModal.reasonSelected"
        class="mt-3"
        sm
        color="red"
        @click="cancelSubscription"
        >ביטול מנוי</RButton
      >
    </RModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { supportChatUrl } from "@/common/settings";
import CompanyApi from "@/api/CompanyApi";
import BillingApi from "@/api/BillingApi";
import PaymentsApi from "@/api/PaymentsApi";
import { formRules } from "@/common/Utils";
import moment from "moment";
import { trackEvent } from "@/services/TrackingService";
import RFieldTextarea from "@/ui/RFieldTextarea.vue";

export default {
  data() {
    return {
      currentNavItem: "settings",
      formRules,
      updateCreditCardModal: {
        isOpen: false,
        creditCardIframeUrl: null,
      },
      cancelModal: {
        isOpen: false,
        reasonSelected: null,
        reasonText: null,
      },
      updateCompanySettingsModal: {
        isOpen: false,
        isLoading: false,
        isValid: false,
        name: null,
        billing: {
          taxName: null,
          taxId: null,
          taxEmail: null,
          contactName: null,
          contactPhone: null,
        },
        data: { disablePdfCompression: false, fromEmail: null, fromName: null },
        logo: null,
      },
      companySettings: {
        companyId: null,
        name: null,
        dataFromEmail: null,
        dataFromName: null,
        billingTaxName: null,
        billingTaxId: null,
        billingTaxEmail: null,
        billingContactName: null,
        billingContactPhone: null,
        email: null,
        billingCardLast4Digits: null,
        billingCardExpirationYear: null,
        billingCardExpirationMonth: null,
        billingPaymentMethod: null,
        dataDisablePdfCompression: null,
        logo: null,
        subscription: {
          id: null,
          planName: null,
          planPeriod: null,
          planGraceDays: null,
          planPrice: null,
          planMaxPrice: null,
          planReports: null,
          planPricePerExtraReport: null,
          startsAt: null,
          endsAt: null,
          trialEndsAt: null,
          isInGracePeriod: null,
          daysLeftInGracePeriod: null,
          isTrial: null,
          autoRenew: null,
        },
      },
      companyNextPayment: {
        success: false,
        message: null,
      },
      payments: [],
      isLoaded: false,
      selectedTab: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["company"]),
    nextPaymentDate() {
      return this.$Utils.convertDatetimeToDisplayDateFormat(
        moment(this.companyNextPayment.periodEndDate).add(1, "days")
      );
    },
    billingCardLast4DigitsWithFakeNumbers() {
      if (!this.companySettings.billingCardLast4Digits) {
        return false;
      }
      return `${this.companySettings.billingCardLast4Digits} •••• •••• •••• ${this.companySettings.billingCardExpirationMonth}/${this.companySettings.billingCardExpirationYear}`;
    },
    billingPlanPeriodText() {
      if (this.companySettings.subscription.planPeriod === "monthly") {
        return "חודשי";
      }
      if (this.companySettings.subscription.planPeriod === "annual") {
        return "שנתי";
      }
      return this.companySettings.planPeriod;
    },
    companySettingsTabs() {
      return [
        {
          label: "פרטי החברה",
          id: "company",
        },
        {
          label: "המנוי שלי",
          id: "subscription",
        },
      ];
    },
  },
  async created() {
    await this.fetchCompanySettings();
    await this.getCompanyNextPayment();
    await this.fetchPayments();
    window.addEventListener("message", this.receiveMessage, false);

    this.isLoaded = true;

    if (this.$route.query["credit-card-modal"]) {
      await this.openUpdateCreditCardModal();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.receiveMessage);
  },
  methods: {
    openSupport() {
      window.open(supportChatUrl, "_blank");
    },
    async cancelSubscription() {
      if (!confirm("האם אתם בטוחים שברצונך לבטל את המנוי?")) {
        return;
      }

      trackEvent("Button Clicked", {
        "Button Name": "Cancel Subscription",
      });

      this.$posthog.capture("subscription_cancel:submit");

      let loader = this.$loading.show();
      try {
        await BillingApi.cancelSubscription({
          reason: this.cancelModal.reasonSelected,
          reasonText: this.cancelModal.reasonText,
        });
        loader.hide();
        setTimeout(() => {
          window.location.reload();
        }, 300);
      } catch (error) {
        loader.hide();
        this.$toast.error(
          error.response.data.message ? error.response.data.message : error
        );
      }
    },
    async activateSubscription() {
      trackEvent("Button Clicked", {
        "Button Name": "Activate Subscription",
      });
      this.$posthog.capture("account_settings:activate_subscription_click");

      let loader = this.$loading.show();
      try {
        await BillingApi.activateSubscription();
        loader.hide();
        setTimeout(() => {
          window.location.reload();
        }, 300);
      } catch (error) {
        loader.hide();
        this.$toast.error(
          error.response.data.message ? error.response.data.message : error
        );
      }
    },
    async downloadInvoice(paymentId) {
      trackEvent("Button Clicked", {
        "Button Name": "Download Invoice",
      });

      this.$posthog.capture("account_settings:invoice_download_click");

      let loader = this.$loading.show();
      try {
        await PaymentsApi.downloadInvoice(paymentId);
        loader.hide();
      } catch (error) {
        loader.hide();
        this.$toast.error(
          error.response.data.message ? error.response.data.message : error
        );
      }
    },
    async tryToFixPayment() {
      let loader = this.$loading.show();
      const response = await BillingApi.fixPayment();

      if (!response.data.isInGracePeriod) {
        this.$toast.success("התשלום התקבל בהצלחה!");
      }

      loader.hide();

      trackEvent("Button Clicked", {
        "Button Name": "Try To Fix Payment",
      });
      this.$posthog.capture("account_settings:fix_payment_click");

      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
    async receiveMessage(event) {
      if (event.data && event.data.type === "payment") {
        this.closeUpdateCreditCardModal();

        if (event.data.success) {
          await this.fetchCompanySettings();
          await this.getCompanyNextPayment();
          trackEvent("Credit Card Updated");
          this.$posthog.capture("account_settings:credit_card_update_submit");
          this.$toast.success("אמצעי התשלום עודכן בהצלחה");
        } else {
          this.$toast.error(event.data.message);
        }
      }
    },
    async fetchCompanySettings() {
      let loader = this.$loading.show();

      const companySettings = await CompanyApi.getCompanySettings();
      this.companySettings = { ...companySettings.data };

      loader.hide();
    },
    async fetchPayments() {
      let loader = this.$loading.show();

      const payments = await PaymentsApi.getPayments();
      this.payments = [...payments.data];

      loader.hide();
    },
    async getCompanyNextPayment() {
      let loader = this.$loading.show();

      const companyNextPayment = await BillingApi.getNextPayment();
      this.companyNextPayment = { ...companyNextPayment.data };

      loader.hide();
    },
    async openUpdateCreditCardModal() {
      trackEvent("Button Clicked", {
        "Button Name": "Open Credit Card Modal",
      });
      this.$posthog.capture("account_settings:credit_card_modal_view");

      this.updateCreditCardModal.creditCardIframeUrl = null;
      this.updateCreditCardModal.isOpen = true;
      await this.setCreditCardIframeUrl();
    },
    closeUpdateCreditCardModal() {
      this.updateCreditCardModal.isOpen = false;
    },
    async setCreditCardIframeUrl() {
      const creditCardIframeUrlResponse =
        await BillingApi.getCreditCardIframeUrl();

      if (
        creditCardIframeUrlResponse.data &&
        creditCardIframeUrlResponse.data.url
      ) {
        this.updateCreditCardModal.creditCardIframeUrl =
          creditCardIframeUrlResponse.data.url;
      } else {
        this.$toast.error("שגיאה בקבלת מסוף אשראי");
      }
    },

    async openUpdateCompanySettingsModal() {
      trackEvent("Button Clicked", {
        "Button Name": "Open Update Company Settings Modal",
      });
      this.$posthog.capture("account_settings:company_settings_modal_view");

      this.updateCompanySettingsModal.isOpen = true;

      this.updateCompanySettingsModal.name = this.companySettings.name;
      this.updateCompanySettingsModal.email = this.companySettings.email;

      this.updateCompanySettingsModal.billing = {
        taxName: this.companySettings.billingTaxName
          ? this.companySettings.billingTaxName
          : null,
        taxId: this.companySettings.billingTaxId
          ? this.companySettings.billingTaxId
          : null,
        taxEmail: this.companySettings.billingTaxEmail
          ? this.companySettings.billingTaxEmail
          : null,
        contactName: this.companySettings.billingContactName
          ? this.companySettings.billingContactName
          : null,
        contactPhone: this.companySettings.billingContactPhone
          ? this.companySettings.billingContactPhone
          : null,
      };
      this.updateCompanySettingsModal.data = {
        disablePdfCompression: this.companySettings.dataDisablePdfCompression,
        fromName: this.companySettings.dataFromName
          ? this.companySettings.dataFromName
          : null,
        fromEmail: this.companySettings.dataFromEmail
          ? this.companySettings.dataFromEmail
          : null,
      };

      this.updateCompanySettingsModal.logo = this.companySettings.logo;
    },
    closeUpdateCompanySettingsModal() {
      this.updateCompanySettingsModal.isOpen = false;
    },
    async submitCompanySettingsModal() {
      if (!this.$refs.updateCompanySettingsForm.checkValidity()) {
        this.$toast.error("יש למלא את כל שדות החובה");
        return;
      }

      this.updateCompanySettingsModal.isLoading = true;

      trackEvent("Button Clicked", {
        "Button Name": "Update Company Settings",
      });
      this.$posthog.capture("account_settings:company_settings_modal_submit");

      let companySettings = { ...this.updateCompanySettingsModal };
      delete companySettings.isOpen;
      delete companySettings.isLoading;
      delete companySettings.isValid;

      try {
        await CompanyApi.updateCompanySettings(companySettings);
        this.updateCompanySettingsModal.isLoading = false;
        this.closeUpdateCompanySettingsModal();
        await this.fetchCompanySettings();
        await this.getCompanyNextPayment();
        this.$toast.success("פרטי החברה עודכנו בצהלחה");
      } catch (error) {
        this.updateCompanySettingsModal.isLoading = false;
        this.$toast.error(
          error.response.data.message ? error.response.data.message : error
        );
      }
    },
  },
  watch: {
    selectedTab() {
      // Update the URL query parameter when the selected tab changes
      if (this.selectedTab === undefined) return;
      const tab = this.companySettingsTabs[this.selectedTab]?.id;

      if (this.$route.params.tab === tab) return;

      this.$router.push({
        params: { tab },
      });
    },
  },

  components: {
    RFieldTextarea,
  },
};
</script>

<style lang="scss">
.support-buttons {
  display: flex;
  gap: 10px;
}
.secure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  a {
    color: #000;
    text-decoration: underline;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.company-settings-container {
  padding: 1.5rem 1.5rem 1.5rem !important;
  border-radius: 1rem;
  background: white;
  margin-top: 1.5rem !important;
  margin: 3rem !important;
  font-family: var(--font-family-noto);
  @media (max-width: 767px) {
    border-radius: 0;
    margin: 0rem !important;
  }

  .v-tab {
    font-size: 1rem !important;
    border-bottom: 1px solid var(--color-gray-300);

    &--active {
      font-family: var(--font-family-noto);
      font-weight: 700;
    }
    &:hover {
      &::before {
        opacity: 0 !important;
      }
    }
  }

  .r-simple-card {
    font-family: var(--font-family-noto);
    border: none;
    color: var(--color-gray-700) !important;
    @media (max-width: 767px) {
      padding-inline: 1rem;
    }
    &-details {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      max-width: 1100px;
      @media (max-width: 767px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
    }
    &-title {
      font-family: var(--font-family-noto);
      font-weight: 700;
      font-size: 1.25rem;
      margin-top: 0.5rem;
    }
    &-subtitle {
      color: var(--color-gray-500) !important;
    }

    .r-simple-card-details__item-label {
      font-family: var(--font-family-noto);
      font-size: 16px !important;
      color: var(--color-gray-700) !important;
    }
    .r-simple-card-details__item-value {
      font-family: var(--font-family-noto);
      font-weight: 700;
      font-size: 16px !important;
      color: var(--color-gray-700) !important;
    }

    .r-button {
      font-family: var(--font-family-noto);
      border-radius: 100px;
      padding: 0.65rem 1rem;
    }
  }

  .cardTable {
    border: none !important;
    color: var(--color-gray-700) !important;
    padding: 1rem 0;
    margin-bottom: 2rem !important;
    &__header__title {
      color: var(--color-gray-700) !important;
      font-family: var(--font-family-noto);
      font-weight: 700;
      font-size: 1.25rem !important;
    }
  }
  .support-container {
    padding: 2rem 1rem;
    h5 {
      font-family: var(--font-family-noto);
      font-weight: 700;
      font-size: 1.25rem !important;
      color: var(--color-gray-700) !important;
    }
    p {
      font-family: var(--font-family-noto);
      font-size: 0.875rem !important;
      color: var(--color-gray-500) !important;
      margin-bottom: 1.5rem;
    }
  }

  .separator {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--color-gray-300) !important;
    padding: 0;
    margin-block: 1rem 0;
  }
}
</style>
