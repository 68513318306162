<template>
  <div>
    <RFieldText class="mb-3" v-model="localItem.settings.headline" label="כותרת" />

    <RFieldAlign
      v-model="localItem.settings.headlineAlign"
      label="ישור כותרת"
    />

    <RFieldSwitch
      v-model="localItem.settings.showDefects"
      label="הצג ממצאים"
    />

    <RFieldSwitch v-model="localItem.settings.showVat" label="הצג מע״מ" />

    <v-list class="pa-0 bg-transparent">
      <draggable
        :list="localItem.settings.totals"
        handle=".v-avatar"
        itemKey="id"
        v-bind="dragOptions"
      >
        <template #item="{ element: total, index: totalId }">
          <v-list-item
            :key="totalId"
            style="border: thin solid rgba(0, 0, 0, 0.12)"
            class="mb-2 px-1 rounded bg-white"
          >
            <div class="d-flex align-center flex-nowrap">
              <v-avatar size="25" class="ml-0">
                <v-icon> mdi-drag</v-icon>
              </v-avatar>

              <v-layout>
                <input
                  v-model="total.text"
                  type="text"
                  placeholder="טקסט"
                  class="m-1"
                  style="flex: 1; width: 150px"
                />
                <input
                  v-model="total.value"
                  type="number"
                  placeholder="ערך"
                  class="ml-1"
                  style="width: 50px"
                />
                <v-select
                  v-model="total.valueType"
                  label="סוג"
                  :items="[
                    { title: '%', value: 'percentage' },
                    { title: '₪', value: 'sum' },
                  ]"
                  hide-details
                  outlined
                  class="grow"
                  density="compact"
                ></v-select>
              </v-layout>

              <v-list-item-action>
                <v-btn
                  icon
                  flat
                  @click="
                    priceTableRemoveTotal(localItem.settings.totals, totalId)
                  "
                >
                  <v-icon color="grey lighten-1">mdi-delete </v-icon>
                </v-btn>
              </v-list-item-action>
            </div>
          </v-list-item>
        </template>
      </draggable>
    </v-list>

    <v-btn
      color="secondary"
      depressed
      class="ma-1 mx-auto d-block"
      @click="priceTableAddTotal(localItem.settings.totals)"
    >
      הוספת שורה
    </v-btn>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { dragOptions } from "@/common/settings";

export default {
  components: {
    draggable,
  },
  props: {
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dragOptions,
      localItem: null,
    };
  },
  watch: {
    localItem(val) {
      this.$emit("update:modelValue", val);
    },
    modelValue(val) {
      this.localItem = val;
    },
  },
  created() {
    if (this.modelValue) {
      this.localItem = this.modelValue;
    }
  },
  methods: {
    updateParentValue(val) {
      this.localItem = val;
    },
    priceTableAddTotal(totals) {
      totals.push({
        text: "",
        isActive: true,
        value: null,
        valueType: "percentage",
      });
    },
    priceTableRemoveTotal(totals, totalId) {
      totals.splice(totalId, 1);
    },
  },
};
</script>
